export const locations = [
  {
    country: 'italy',
    city: 'rome',
    countryCode: 'IT',
    lat: 41.9028,
    lng: 12.4964,
  },
  {
    country: 'poland',
    city: 'warsaw',
    countryCode: 'PL',
    lat: 52.244090,
    lng: 21.002239,
  },
  {
    country: 'poland',
    city: 'gdansk',
    countryCode: 'PL',
    lat: 54.352000,
    lng: 18.646600,
  },
  {
    country: 'poland',
    city: 'krakow',
    countryCode: 'PL',
    lat: 50.064700,
    lng: 19.945000,
  },
  {
    country: 'switzerland',
    city: 'zurich',
    countryCode: 'CH',
    lat: 47.3769,
    lng: 8.5417,
  },
  {
    country: 'netherlands',
    city: 'utrecht',
    countryCode: 'NL',
    lat: 52.0907,
    lng: 5.1214,
  },
  {
    country: 'netherlands',
    city: 'rotterdam',
    countryCode: 'NL',
    lat: 51.9244,
    lng: 4.4777,
  },
  {
    country: 'netherlands',
    city: 'amsterdam',
    countryCode: 'NL',
    lat: 51.9244,
    lng: 4.4777,
  },
  {
    country: 'france',
    city: 'nice',
    countryCode: 'FR',
    lat: 43.7102,
    lng: 7.2620,
  },
  {
    country: 'ireland',
    city: 'dublin',
    countryCode: 'IE',
    lat: 53.3948,
    lng: -6.2603,
  },
  {
    country: 'usa',
    city: 'austin',
    countryCode: 'US',
    lat: 30.2672,
    lng: -97.7431,
  },
  {
    country: 'portugal',
    city: 'lisbon',
    countryCode: 'PT',
    lat: 38.7223,
    lng: -9.1393,
  },
  {
    country: 'uae',
    city: 'dubai',
    countryCode: 'AE',
    lat: 25.2048,
    lng: 55.2708,
  },
  {
    country: 'russia',
    city: 'novosibirsk',
    countryCode: 'RU',
    lat: 54.9833,
    lng: 82.8964,
  },
  {
    country: 'spain',
    city: 'barcelona',
    countryCode: 'ES',
    lat: 41.3874,
    lng: 2.1686,
  },
  {
    country: 'usa',
    city: 'chicago',
    countryCode: 'US',
    lat: 41.8781,
    lng: -87.6298,
  },
];
export const locationsObject = locations.reduce((prev, next) => {
  prev[next.city] = next;
  return prev;
}, {} as Record<string, typeof locations[0]>);
