import React, { forwardRef } from 'react';
import { useNavigate } from 'react-router-dom';
import { Card, CardContent, CardHeader, Grid, Typography } from '@mui/material';
import {
  Phone,
  AlternateEmail,
  AccountBalance,
  Map as MapIcon,
  LinkedIn,
  YouTube,
  Instagram,
} from '@mui/icons-material';
import { makeStyles } from '@mui/styles';

import { SectionTitle } from 'shared/sections/SectionTitle/SectionTitle';
import { Map } from 'shared/components/Map/Map';
import { locations } from 'data/locations.data';
import { MapMarker } from 'shared/components/Map/MapMarker';
import { MenuItemElement } from 'contexts/LayoutContext/LayoutContext.types';


const lat = 48.1351;
const lng = 0.5820;

const useStyles = makeStyles(theme => ({
  root: {
    position: 'relative',
    width: '100%',
    minHeight: '100vh',
  },
  card: {
    width: 540,
    margin: 20,
    position: 'absolute',
    top: '50%',
    left: 60,
    marginTop: -150,
    zIndex: 10000,
    [theme.breakpoints.down('md')]: {
      position: 'relative',
      top: 'unset',
      left: 'unset',
      margin: 'auto',
      width: '100%',
    },
  },
}));

export const ContactUsSection = forwardRef<MenuItemElement>((props, ref) => {
  const classes = useStyles();
  const navigate = useNavigate();

  return (
    <div ref={ref} className={classes.root}>
      <Card className={classes.card}>
        <CardHeader
          title={
            <SectionTitle variant="h5" color="primary" onEnterViewport={() => navigate('/contact-us')}>
              CVT Sp. z. o.o.
            </SectionTitle>
          }
        />
        <CardContent>
          {/*<Grid container alignItems="center" wrap="nowrap">*/}
          {/*  <MapIcon style={{marginRight: 10}}/>*/}
          {/*  <Typography variant="body1">*/}
          {/*    <a href="https://goo.gl/maps/boBbjtaFDmEFcvjm9">*/}
          {/*      Kempttalstr. 49, 8330 Pfäffikon Zurich, Switzerland*/}
          {/*    </a>*/}
          {/*  </Typography>*/}
          {/*</Grid>*/}
          {/*<br/>*/}
          <Grid container alignItems="center" wrap="nowrap">
            <MapIcon style={{marginRight: 10}}/>
            <Typography variant="body1">
              <a href="https://goo.gl/maps/zJPfEVGVot7VotNU9">
                Plac Bankowy 2, 00-095 Warsaw, Poland
              </a>
            </Typography>
          </Grid>
          <br/>
          <Grid container alignItems="center">
            <AccountBalance style={{marginRight: 10}}/>
            <Typography variant="body1">NIP: 525 281 97 07</Typography>
          </Grid>
          <br/>
          <Grid container alignItems="center">
            <AlternateEmail style={{marginRight: 10}}/>
            <Typography variant="body1">
              <a href="mailto:info@cvt.services">
                info@cvt.services
              </a>
            </Typography>
          </Grid>
          <br/>

          <Grid container>
            <Grid item xs={6}>
              <Grid container alignItems="center">
                <Phone style={{marginRight: 10}}/>
                <Typography variant="body1">
                  <a href="tel:+48536951330">
                    +48 536 951 330
                  </a>
                </Typography>
              </Grid>
              <br/>
              <Grid container alignItems="center">
                <LinkedIn style={{marginRight: 10}}/>
                <Typography variant="body1">
                  <a href="https://linkedin.com/company/cvtservices" target="_blank" rel="noreferrer">
                    LinkedIn
                  </a>
                </Typography>
              </Grid>
              <br/>
              <Grid container alignItems="center">
                <YouTube style={{marginRight: 10}}/>
                <Typography variant="body1">
                  <a href="https://www.youtube.com/@cvtai" target="_blank" rel="noreferrer">
                    YouTube
                  </a>
                </Typography>
              </Grid>
            </Grid>
            <Grid item xs={6} sx={{ textAlign: 'right' }}>
              <Grid container alignItems="center" justifyContent="right">
                <Typography variant="body1">
                  <a href="https://www.instagram.com/creatingvaluewithtech" target="_blank" rel="noreferrer">
                    Instagram
                  </a>
                </Typography>
                <Instagram style={{ marginLeft: 10 }}/>
              </Grid>
              <br/>
              <Grid container alignItems="center" justifyContent="right">
                <Typography variant="body1">
                  <a href="https://www.tiktok.com/@cvtai" target="_blank" rel="noreferrer">
                    TikTok
                  </a>
                </Typography>
                <svg xmlns="http://www.w3.org/2000/svg"  viewBox="0 0 24 24" width="24px" height="24px" style={{ marginLeft: 10 }}>
                  <path fill="#ececec" d="M 6 3 C 4.3550302 3 3 4.3550302 3 6 L 3 18 C 3 19.64497 4.3550302 21 6 21 L 18 21 C 19.64497 21 21 19.64497 21 18 L 21 6 C 21 4.3550302 19.64497 3 18 3 L 6 3 z M 12 7 L 14 7 C 14 8.005 15.471 9 16 9 L 16 11 C 15.395 11 14.668 10.734156 14 10.285156 L 14 14 C 14 15.654 12.654 17 11 17 C 9.346 17 8 15.654 8 14 C 8 12.346 9.346 11 11 11 L 11 13 C 10.448 13 10 13.449 10 14 C 10 14.551 10.448 15 11 15 C 11.552 15 12 14.551 12 14 L 12 7 z"/>
                </svg>
              </Grid>
              <br/>
              <Grid container alignItems="center" justifyContent="right">
                <Typography variant="body1">
                  <a href="https://open.spotify.com/show/4FyzTXuAIPjmwC2FlBIQv0" target="_blank" rel="noreferrer">
                    Spotify
                  </a>
                </Typography>
                <svg xmlns="http://www.w3.org/2000/svg" width="24px" height="24px" viewBox="0 0 24 24" style={{ marginLeft: 10 }}>
                  <path fill="#ececec" d="M17.9 10.9C14.7 9 9.35 8.8 6.3 9.75c-.5.15-1-.15-1.15-.6c-.15-.5.15-1 .6-1.15c3.55-1.05 9.4-.85 13.1 1.35c.45.25.6.85.35 1.3c-.25.35-.85.5-1.3.25m-.1 2.8c-.25.35-.7.5-1.05.25c-2.7-1.65-6.8-2.15-9.95-1.15c-.4.1-.85-.1-.95-.5s.1-.85.5-.95c3.65-1.1 8.15-.55 11.25 1.35c.3.15.45.65.2 1m-1.2 2.75c-.2.3-.55.4-.85.2c-2.35-1.45-5.3-1.75-8.8-.95c-.35.1-.65-.15-.75-.45c-.1-.35.15-.65.45-.75c3.8-.85 7.1-.5 9.7 1.1c.35.15.4.55.25.85M12 2A10 10 0 0 0 2 12a10 10 0 0 0 10 10a10 10 0 0 0 10-10A10 10 0 0 0 12 2"/>
                </svg>
              </Grid>
            </Grid>
          </Grid>
        </CardContent>
      </Card>
      <Map lat={lat} lng={lng}>
        {locations.map(({city, lat, lng}, i) => (
          <MapMarker
            key={i}
            lat={lat}
            lng={lng}
            city={city}
          />
        ))}
      </Map>
    </div>
  );
});
