import React, {
  FC,
  createContext,
  useState,
  useEffect, ReactNode,
} from 'react';

import { dictionary as en } from './languages/en';
import { ContextProps, SupportedLanguage } from './types';

let defaultLanguage: SupportedLanguage = 'en';
let defaultDictionary = en;

const defaultContext: ContextProps = {
  language: defaultLanguage,
  dictionary: defaultDictionary,
  setLanguage: () => null,
  languageOptions: {
    en: 'English',
    it: 'Italiano',
    pl: 'Polski',
    de: 'Deutsch',
    nl: 'Nederlands',
  },
};


export const LocalizationContext = createContext(defaultContext);


type Props = {
  children: ReactNode;
}

export const LocalizationContextProvider: FC<Props> = ({ children }) => {

  const [language, setLanguage] = useState<SupportedLanguage>(defaultContext.language);
  const [location] = useState();
  const dictionary = en;

  useEffect(() => {
    localStorage.setItem('language', language);
  }, [language]);

  // todo: this api no more free, need to buy appropriate plan
  // useEffect(() => {
    // fetch('https://ip-api.com/json').then(result => result.json().then(location => setLocation(location)));
  // }, [setLocation]);

  return (
    <LocalizationContext.Provider
      value={{
        language,
        dictionary,
        setLanguage,
        languageOptions: defaultContext.languageOptions,
        location,
      }}
    >
      {children}
    </LocalizationContext.Provider>
  );
};
