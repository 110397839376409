import React, {
  FC,
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
  ReactNode
} from 'react';
import { Code, Group, Help, Home, MenuBook, Phone, Work, Star, Science } from '@mui/icons-material';
import { useMediaQuery, useTheme } from '@mui/material';

import { LocalizationContext } from 'contexts/LocalizationContext/LocalizationContext';
import { LayoutContextState, MenuItem, MenuItemRef } from 'contexts/LayoutContext/LayoutContext.types';

const defaultContext: LayoutContextState = {
  menuOpen: false,
  setMenuOpen: () => {},
  useDarkTheme: false,
  setUseDarkTheme: () => {},
  userMenuOpen: false,
  setUserMenuOpen: () => {},
  playAnimations: false,
  setPlayAnimations: () => {},
  isMobile: false,
  menuItems: [],

  autoScrollToSection: () => {},
  scrollToSection: () => {},
};

const scrollTo = (sectionRef: MenuItemRef) => {
  sectionRef.current?.scrollIntoView({
    behavior: 'smooth'
  });
};

export const LayoutContext = createContext(defaultContext);

type Props = {
  children: ReactNode;
}

export const LayoutContextProvider: FC<Props> = ({ children }) => {

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const { dictionary } = useContext(LocalizationContext);

  const homeSectionRef = useRef<HTMLDivElement>(null);
  const testimonialsSectionRef = useRef<HTMLDivElement>(null);
  const portfolioSectionRef = useRef<HTMLDivElement>(null);
  const techStackSectionRef = useRef<HTMLDivElement>(null);
  const successStoriesSectionRef = useRef<HTMLDivElement>(null);
  const teamSectionRef = useRef<HTMLDivElement>(null);
  const contactUsSectionRef = useRef<HTMLDivElement>(null);
  const careersSectionRef = useRef<HTMLDivElement>(null);
  const whySectionRef = useRef<HTMLDivElement>(null);

  const [useDarkTheme, setUseDarkTheme] = useState(true);
  const [menuOpen, setMenuOpen] = useState(false);
  const [userMenuOpen, setUserMenuOpen] = useState(false);
  const [playAnimations, setPlayAnimations] = useState(['true',null].includes(localStorage.getItem('playAnimations')));

  const menuItems = useMemo(() => [
    {name: dictionary.home, to: '/home', icon: <Home/>, ref: homeSectionRef},
    {name: dictionary.whyUs, to: '/why', icon: <Help/>, ref: whySectionRef},
    {name: dictionary.successStories, to: '/success-stories', icon: <Star/>, ref: successStoriesSectionRef},
    // { name: dictionary.testimonials, to: '/testimonials', icon: <Star/>, ref: testimonialsSectionRef },
    {name: dictionary.portfolio, to: '/portfolio', icon: <MenuBook/>, ref: portfolioSectionRef},
    {name: dictionary.techstack, to: '/tech-stack', icon: <Code/>, ref: techStackSectionRef},
    {name: dictionary.labs, to: '/labs', icon: <Science/>, menuOnly: true},
    {name: dictionary.team, to: '/team', icon: <Group/>, ref: teamSectionRef},
    {name: dictionary.careersSectionTitle, to: '/careers', icon: <Work/>, ref: careersSectionRef},
    {name: dictionary.contactUs, to: '/contact-us', icon: <Phone/>, ref: contactUsSectionRef},
  ], [dictionary]);

  const autoScrollToSection = useCallback((pathname: string) => {
    if(pathname === 'mentour') {
      return;
    }
    const locationToScroll = menuItems.find((menuItem) => menuItem.to === `/${pathname}`);

    if (locationToScroll?.ref) {
      scrollTo(locationToScroll?.ref);
    }
  }, [menuItems]);

  const scrollToSection = useCallback((section: MenuItem) => {
    if(section.ref) {
      scrollTo(section.ref);
    }
  }, []);

  useEffect(() => {
    localStorage.setItem('playAnimations', `${playAnimations}`);
  }, [playAnimations]);

  return (
    <LayoutContext.Provider
      value={{
        menuOpen,
        setMenuOpen,
        menuItems,
        userMenuOpen,
        setUserMenuOpen,
        useDarkTheme,
        setUseDarkTheme,
        homeSectionRef,
        testimonialsSectionRef,
        whySectionRef,
        portfolioSectionRef,
        techStackSectionRef,
        successStoriesSectionRef,
        teamSectionRef,
        contactUsSectionRef,
        careersSectionRef,
        autoScrollToSection,
        scrollToSection,
        playAnimations,
        setPlayAnimations,
        isMobile,
      }}
    >
      {children}
    </LayoutContext.Provider>
  );
};
