import React, { FC } from 'react';
import { styled } from '@mui/styles';

import mobileOverlay from 'shared/img/mobile.webp';

const MobileContainer = styled('div')(({ theme }) => ({
  position: 'absolute',
  top: 50,
  right: -50,
  padding: 5,
  zIndex: 5,

  transitionDuration: '200ms',
  transform: 'scale(1)',
  '&:hover': {
    transform: 'scale(1.1)',
  },

  [theme.breakpoints.down('sm')]: {
    top: 0,
    right: -25,
    transform: 'scale(0.7)'
  }
}));

const MobileOverlay = styled('img')(() => ({
  width: 108 * 1.7,
  height: 182 * 1.7,
  zIndex: 10,
}));

const MobileImage = styled('img')(() => ({
  position: 'absolute',
  top: 29,
  right: 19,
  height: 255,
  borderRadius: 15,
  zIndex: -1,
}));

type Props = {
  title: string;
  mobileImage?: string;
};

export const PortfolioSectionMobile: FC<Props> = ({
  title,
  mobileImage,
}) => {
  return (
    <MobileContainer>
      <MobileImage
        title={title}
        src={mobileImage}
      />
      <MobileOverlay
        title={title}
        src={mobileOverlay}
      />
    </MobileContainer>
  );
}