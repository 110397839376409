import React, { forwardRef, useContext, Fragment } from 'react';
import { useNavigate } from 'react-router-dom';
import { useTheme, Box, Alert } from '@mui/material';

import { BaseSection } from 'shared/sections/BaseSection/BaseSection';
import { SectionTitle } from 'shared/sections/SectionTitle/SectionTitle';
import { MenuItemElement } from 'contexts/LayoutContext/LayoutContext.types';
import { LayoutContext } from 'contexts/LayoutContext/LayoutContext';
import { whySectionData } from 'data/whySection.data';
import { WhySectionSubtitle } from 'shared/sections/WhySection/components/WhySectionSubtitle';
import { WhySectionComparisonItem } from 'shared/sections/WhySection/components/WhySectionComparisonItem';
import { WhySectionComparisonHeader } from 'shared/sections/WhySection/components/WhySectionComparisonHeader';



const [firstItem, ...data] = whySectionData;

export const WhySection = forwardRef<MenuItemElement>((props, ref) => {
  const navigate = useNavigate();
  const theme = useTheme();

  const { isMobile } = useContext(LayoutContext);

  return (
    <BaseSection
      ref={ref}
      style={{
        padding: 12,
        background: theme.palette.mode === 'dark'
          ? 'linear-gradient(180deg, rgba(7, 7, 8, 0) 0%, #070708 15.1%, #070708 85.42%, rgba(7, 7, 8, 0) 100%)'
          : 'linear-gradient(180deg, rgba(7, 7, 8, 0) 0%, #ececec 15.1%, #ececec 85.42%, rgba(7, 7, 8, 0) 100%)',
      }}
    >
      <SectionTitle onEnterViewport={() => navigate('/why')}>
        Why choose us?
      </SectionTitle>
      <WhySectionSubtitle/>
      {isMobile ? (
        <Box mt={2}>
          {whySectionData.map(([onYourOwn, withUs], i) => (
            <Box textAlign="left" mb={3}>
              <Alert severity="error" variant="outlined" sx={{ mb: 1 }}>{onYourOwn}</Alert>
              <Alert severity="success" variant="outlined">{withUs}</Alert>
            </Box>
          ))}
        </Box>
      ) : (
        <Fragment>
          <WhySectionComparisonHeader
            pros={firstItem[1]}
            cons={firstItem[0]}
          />
          {data.map(([a, b], index) => (
            <WhySectionComparisonItem
              key={index}
              pros={b}
              cons={a}
            />
          ))}
        </Fragment>
      )}
    </BaseSection>
  );
});
