import { getAnalytics, logEvent } from 'firebase/analytics';

import { app } from 'clients/firebaseClient';

const analytics = process.env.NODE_ENV === 'production'
  ? getAnalytics(app) :
  {} as ReturnType<typeof getAnalytics>;

export const trackPageview = () => {
  if (process.env.NODE_ENV === 'production') {
    logEvent(analytics, 'page_view', {
      page_path: window.location.pathname,
      page_title: document.title,
      page_location: window.location.href,
    });
  }
};
