import React, { useContext, FC, ComponentClass } from 'react';
import GoogleMap, { Props as GoogleMapProps } from 'google-map-react';

import { LayoutContext } from 'contexts/LayoutContext/LayoutContext';

import { dark } from './Map.dark.styles';
import { light } from './Map.light.styles';

const apiKey = 'AIzaSyCU2WzvsDBYYfcWdraqEj1PONbh4q90aVc';

const ReactGoogleMap = GoogleMap as ComponentClass<GoogleMapProps>;
export const Map: FC<any> = ({lat, lng, children}) => {

  const {useDarkTheme} = useContext(LayoutContext);

  const styles = useDarkTheme ? dark : light;

  return (
    <div style={{width: '100%', height: '100vh'}}>
      <ReactGoogleMap
        bootstrapURLKeys={{key: apiKey}}
        defaultCenter={{lat, lng}}
        defaultZoom={2}
        options={{
          //@ts-ignore
          styles,
          fullscreenControl: false,
        }}
      >
        {children}
      </ReactGoogleMap>
    </div>
  )
}
