import React, { FC, useContext } from 'react';
import { IconButton } from '@mui/material';

import { LayoutContext } from 'contexts/LayoutContext/LayoutContext';


type Props = {
  active?: boolean;
  completed?: boolean;
  icon: number;
}

export const ProgressBarStep: FC<Props> = ({ icon: step, active }) => {
  const { menuItems, scrollToSection } = useContext(LayoutContext);
  const menuItem = menuItems[step];

  return (
    <IconButton
      sx={{
        width: 40,
        height: 40,
      }}
      onClick={() => {
        scrollToSection(menuItem);
      }}
      {...(active && {color: 'primary'})}
    >
      {menuItem.icon}
    </IconButton>
  );
};
