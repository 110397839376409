export const routes = {
  home: '/',
  portfolio: '/',
  techStack: '/tech-stack',
  team: '/team',
  careers: '/careers',
  labs: '/labs',
  contactUs: '/contact-us',
  portfolioClient: (slug = ':slug') => `/portfolio/${slug}`,
  labsProject: (project = ':project') => `/labs/${project}`,
}

export const email = 'info@cvt.services';
export const phone = '+48536951330';
