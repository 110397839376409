import React, { forwardRef, useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import { Avatar, Button, Card, CardActions, CardContent, CardHeader, Grid, Typography } from '@mui/material';
import { Mail, Phone } from '@mui/icons-material';

import { email, phone } from 'config';
import { openPositions } from 'data/careers.data';
import { LayoutContext } from 'contexts/LayoutContext/LayoutContext';
import { BaseSection } from 'shared/sections/BaseSection/BaseSection';
import { SectionTitle } from 'shared/sections/SectionTitle/SectionTitle';
import { LocalizationContext } from 'contexts/LocalizationContext/LocalizationContext';
import { MenuItemElement } from 'contexts/LayoutContext/LayoutContext.types';

export const CareersSection = forwardRef<MenuItemElement>((props, ref) => {
  const navigate = useNavigate();
  const { dictionary, location } = useContext(LocalizationContext);
  const { isMobile } = useContext(LayoutContext);

  return (
    <BaseSection ref={ref}>
      <Grid
        item
        sx={{
          width: '100%',
          textAlign: 'center',
          p: isMobile ? 0 : 8.75,
          pb: 0,
      }}>
        <SectionTitle onEnterViewport={() => navigate('/careers')}>
          {dictionary.careersSectionTitle}
        </SectionTitle>
        <br/>
        <Typography variant="body1" fontSize={18}>
          {dictionary.careersSectionDescription}
        </Typography>
        <br/>
      </Grid>
      <Grid
        item
        sx={{
          p: isMobile ? 0 : 8.75,
          pt: 0,
        }}
      >
        <Grid container alignItems="center" justifyContent="center" spacing={2} sx={{ textAlign: 'left' }}>
          {openPositions.map(({ title, type, location: positionLocation, description, experience, salary }, i) => {
            return (
              <Grid key={i} item xs={12} sm={6} lg={3}>
                <Card>
                  <CardHeader
                    avatar={<Avatar alt="?"/>}
                    title={<Typography variant="h6">{title}</Typography>}
                    subheader={`${type} - ${positionLocation} - ${experience}`}
                  />
                  <CardContent>
                    <Typography variant="subtitle2" style={{ minHeight: 130 }}>
                      {description}
                    </Typography>
                    <br/>
                    {location?.countryCode === 'PL' && (
                      <Typography variant="subtitle2" style={{ fontWeight: 'bold' }}>
                        {salary}
                      </Typography>
                    )}
                  </CardContent>
                  <CardActions>
                    <Grid container alignItems="center" justifyContent="flex-end" spacing={2}>
                      <Grid item>
                        <Button variant="outlined" endIcon={<Phone/>} href={`tel:${phone}`}>
                          Call Us
                        </Button>
                      </Grid>
                      <Grid item>
                        <Button variant="contained" color="primary" endIcon={<Mail/>} href={`mailto:${email}`}>
                          Email Us
                        </Button>
                      </Grid>
                    </Grid>
                  </CardActions>
                </Card>
              </Grid>
            )
          })}
        </Grid>
      </Grid>
    </BaseSection>
  );
});
