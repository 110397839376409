import React, { useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import { AppBar, Toolbar, IconButton, Typography, Tooltip } from '@mui/material';
import { Menu, Brightness4, Brightness7, Phone, Science } from '@mui/icons-material';

import { routes } from 'config';
import logo from 'shared/img/logos/cvt.svg';
import { LayoutContext } from 'contexts/LayoutContext/LayoutContext';
import { LocalizationContext } from 'contexts/LocalizationContext/LocalizationContext';

const showPlayAnimations = true;

export const Nav = () => {
  const navigate = useNavigate();
  const { setMenuOpen, useDarkTheme, setUseDarkTheme } = useContext(LayoutContext);
  const { dictionary } = useContext(LocalizationContext);

  const ToggleModeIcon = useDarkTheme ? Brightness7 : Brightness4;

  return (
    <AppBar color="transparent" elevation={0}>
      <Toolbar sx={{ display: 'flex', alignItems: 'center' }}>
        <IconButton onClick={() => setMenuOpen((prevState: boolean) => !prevState)} edge="start">
          <Menu/>
        </IconButton>
        <Typography variant="h5" sx={{ cursor: 'pointer' }} onClick={() => navigate('/')}>
          <img alt="CVT Logo" src={logo} style={{ height: 40, width: 'auto', marginBottom: -14, marginLeft: 12 }}/>
        </Typography>
        <div style={{ marginLeft: 'auto'}} />
        <Tooltip title={<Typography variant="body1">{dictionary.giveUsACall}</Typography>}>
          <IconButton href="tel:+48536951330">
            <Phone/>
          </IconButton>
        </Tooltip>
        {showPlayAnimations && (
          <Tooltip title={<Typography variant="body1">{dictionary.cvtLabs}</Typography>}>
            <IconButton onClick={() => navigate(routes.labs)}>
              <Science/>
            </IconButton>
          </Tooltip>
        )}
        <Tooltip title={<Typography variant="body1">{useDarkTheme ? dictionary.switchToLightMode : dictionary.switchToDarkMode}</Typography>}>
          <IconButton onClick={() => setUseDarkTheme((prevState: boolean) => !prevState)}>
            <ToggleModeIcon/>
          </IconButton>
        </Tooltip>
        {/*<IconButton onClick={() => setUserMenuOpen((prevState: boolean) => !prevState)} edge="end">*/}
        {/*  <PersonRounded/>*/}
        {/*</IconButton>*/}
      </Toolbar>
    </AppBar>
  )
}
