import React, { forwardRef } from 'react';
import { Box, Grid, Paper, Typography } from '@mui/material';

import { BaseSection } from 'shared/sections/BaseSection/BaseSection';
import { MenuItemElement } from 'contexts/LayoutContext/LayoutContext.types';


export const MissionVisionValuesSection = forwardRef<MenuItemElement>((props, ref) => {
  return (
    <BaseSection ref={ref}>
      <Grid container justifyContent="center" spacing={4}>
        <Grid item xs={12} sm={10}>
          <Typography variant="h3" color="white">Our Mission & Vision</Typography>
          <br/>
          <Typography fontSize={18}>
            <i>"Make technology more useful for humanity by helping founders deliver value over features"</i>
          </Typography>
        </Grid>
        <Grid item xs={12} sm={10}>
          <Box component={Paper} p={2} pb={6}>
            <Typography variant="h3" color="white">Our Values</Typography>
            <br/>
            <Grid container spacing={4}>
              <Grid item xs={12} sm={4}>
                <Typography variant="h2">C</Typography>
                <br/>
                <Typography variant="h5">
                  Creativity,
                  Courage,
                  Collaboration,
                  Communication,
                  Cool,
                  Critical Thinking,
                  Clean Code
                </Typography>
              </Grid>
              <Grid item xs={12} sm={4}>
                <Typography variant="h2">V</Typography>
                <br/>
                <Typography variant="h5">
                  Value,
                  Vision,
                  Velocity,
                  Vigour,
                  Valour,
                  Validation
                </Typography>
              </Grid>
              <Grid item xs={12} sm={4}>
                <Typography variant="h2">T</Typography>
                <br/>
                <Typography variant="h5">
                  Technology,
                  Team Spirit,
                  Trust,
                  Taking Initiative,
                  Transparency,
                  Thrill,
                  Thriving,
                  Truth,
                  Trial and Error,
                  Time
                </Typography>
              </Grid>
            </Grid>
          </Box>
        </Grid>
      </Grid>
    </BaseSection>
  );
});
