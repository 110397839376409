import React, { useContext } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { Drawer, Typography, List, ListItemIcon, ListItemText, ListItem } from '@mui/material';

import { palette } from 'styles/muiTheme';
import { LayoutContext } from 'contexts/LayoutContext/LayoutContext';
import logo from 'shared/img/logos/cvt.svg';


export const Menu = () => {
  const navigate = useNavigate()
  const {pathname} = useLocation();
  const {menuItems, menuOpen, setMenuOpen, scrollToSection} = useContext(LayoutContext);

  const style = { padding: '20px 55px 20px 40px' };

  return (
    <Drawer open={menuOpen} onClose={() => setMenuOpen(false)}>
      <List style={{ display: 'flex', flexDirection: 'column', height: '100%' }}>
        <ListItem style={style}>
          <ListItemText>
            <Typography variant="h5" align="center" style={{cursor: 'pointer'}} onClick={() => {
              navigate('/');
              setMenuOpen(false);
            }}>
              <img alt="CVT Logo" src={logo} style={{ height: 50, width: 'auto', margin: 12 }}/><br/>
              CVT Services
            </Typography>
          </ListItemText>
        </ListItem>
        {menuItems.map(({name, to, icon, menuOnly, ...rest}: any, i: number) => (
          <ListItem
            key={i}
            onClick={() => {
              setMenuOpen(false);
              if(menuOnly) {
                navigate(to)
              } else {
                if(pathname === '/labs') {
                  navigate(to)
                }
                setTimeout(() => scrollToSection(rest), 200);
              }
            }}
            style={{
              ...style,
              ...(i === menuItems.length - 1 && { marginTop: 'auto' }),
              cursor: 'pointer',
            }}
          >
            <ListItemIcon {...(to === pathname && {style: {color: palette.primary}})}>{icon}</ListItemIcon>
            <ListItemText {...(to === pathname && {style: {color: palette.primary}})}>{name}</ListItemText>
          </ListItem>
        ))}
      </List>
    </Drawer>
  )
}
