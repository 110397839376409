import hartmann from 'shared/img/successStories/hartmann-manuel.webp';
import aron from 'shared/img/successStories/aron-schilder.webp';
import ilaria from 'shared/img/successStories/ilaria-gaddini.webp';
import ihPlaceholder from 'shared/img/successStories/ih-placeholder.webp';

export type VideoObject = {
  title: string;
  url: string;
}

export type SuccessStory = {
  customer: string;
  jobTitle: string;
  company: string;
  linkedIn: string;
  avatar?: string;
  video?: VideoObject;
  placeholder?: string;
}

export const successStories: SuccessStory[] = [
  {
    customer: 'Manuel Hartman',
    jobTitle: 'CEO',
    company: 'SalesPlaybook',
    linkedIn: 'https://www.linkedin.com/in/hartmannmanuel/',
    avatar: hartmann,
    video: {
      title: 'How CVT helped SalesPlaybook go from 0 to 1M € ARR in 12 months.',
      url: 'dHxRY-mzWHc',
    },
  },
  {
    customer: 'Aron Schilder',
    jobTitle: 'CEO',
    company: 'Recruit Robin',
    linkedIn: 'https://www.linkedin.com/in/aronschilder/',
    avatar: aron,
    video: {
      title: 'How CVT helped RecruitRobin go from 0 to 1.5M € ARR in 18 months.',
      url: 'tcov-_i2brE',
    },
  },
  {
    customer: 'Ilaria Gaddini',
    jobTitle: 'CEO',
    company: 'International House',
    linkedIn: 'https://www.linkedin.com/in/ilaria-gaddini-7b775a140/',
    avatar: ilaria,
    placeholder: ihPlaceholder
  },
];
