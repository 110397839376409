//@ts-nocheck
import { palette } from 'styles/muiTheme';
import {
  AWS,
  Azure,
  GCP,
  JS,
  TS,
  Python,
  PHP,
  CSharp,
  Node,
  React,
  Firebase,
  CSS3,
  HTML5,
  DotNet,
  ElasticSearch, MongoDB, Redis, MYSQL, MSSQL, PostgreSQL,
  Beam, Airflow,
} from 'shared/icons'

export const tech = {
  techstack: {
    label: 'Tech Stack',
    level: 1,
    value: 10,
    color: palette.primary,
  },
  backend: {
    label: 'Backend',
    level: 1,
    value: 5,
    color: palette.primary,
  },
  frontend: {
    label: 'Frontend',
    level: 1,
    value: 5,
    color: palette.primary,
  },
  dataEngineering: {
    label: "Data Engineering",
    value: 5,
    level: 1,
    color: palette.primary,
  },
  dataScience: {
    label: 'Data Science',
    level: 1,
    value: 5,
    color: palette.primary,
  },
  devops: {
    label: 'DevOps',
    level: 1,
    value: 5,
    color: palette.primary,
  },
  python: {
    label: 'Python',
    value: 4,
    image: Python,
  },
  php: {
    label: 'PHP',
    value: 4,
    image: PHP,
  },
  csharp: {
    label: 'C#',
    value: 4,
    image: CSharp,
  },
  js: {
    label: 'Javascript',
    value: 5,
    image: JS,
  },
  node: {
    label: 'NodeJS',
    value: 3,
    image: Node,
  },
  ts: {
    label: 'Typescript',
    value: 3,
    image: TS,
  },
  capacitor: {
    label: 'Capacitor',
    value: 5,
  },
  react: {
    label: 'React',
    value: 5,
    image: React,
  },
  reactNative: {
    label: 'React Native',
    value: 4,
  },
  reactQuery: {
    label: 'React Query',
    value: 3,
  },
  reactHookForm: {
    label: 'React Hook Form',
    value: 3,
  },
  materialUI: {
    label: 'Material UI',
    value: 3,
  },
  django: {
    label: 'Django',
    value: 4,
  },
  flask: {
    label: 'Flask',
  },
  fastapi: {
    label: 'Fast API',
  },
  falcon: {
    label: 'Falcon',
    value: 3,
  },
  express: {
    label: 'Express JS',
  },
  wordpress: {
    label: 'WordPress',
  },
  woocommerce: {
    label: 'WooCommerce',
  },
  magento: {
    label: 'Magento',
  },
  css3: {
    label: 'CSS3',
    value: 5,
    image: CSS3,
  },
  html5: {
    label: 'HTML5',
    value: 5,
    image: HTML5,
  },
  ci: {
    label: 'CI',
    value: 5,
  },
  cd: {
    label: 'CD',
    value: 5,
  },
  serverless: {
    label: 'Serverless',
    value: 5,
  },
  microservices: {
    label: 'Micro Services',
    value: 5,
  },
  infrastructure: {
    label: 'Infrastructure',
    value: 5,
  },
  aws: {
    label: 'AWS',
    value: 3,
    image: AWS,
  },
  gcp: {
    label: 'GCP',
    value: 5,
    image: GCP,
  },
  azure: {
    label: 'Azure',
    value: 2,
    image: Azure,
  },
  firebase: {
    label: 'Firebase',
    value: 2,
    image: Firebase,
  },
  db: {
    label: 'Databases',
    value: 5,
  },
  relational: {
    label: 'Relational',
    value: 5,
  },
  posgresql: {
    label: 'PostgreSQL',
    value: 3,
    image: PostgreSQL,
  },
  mssql: {
    label: 'MS SQL',
    image: MSSQL,
  },
  mysql: {
    label: 'MySQL',
    image: MYSQL,
  },
  nonRelational: {
    label: 'Non Relational',
    value: 5,
  },
  datastore: {
    label: 'GCP Datastore',
  },
  redis: {
    label: 'Redis',
    image: Redis,
  },
  mongodb: {
    label: 'Mongo DB',
    value: 3,
    image: MongoDB,
  },
  es: {
    label: 'Elastic Search',
    value: 4,
    image: ElasticSearch,
  },
  dotnet: {
    label: '.NET',
    value: 3,
    image: DotNet,
  },
  ml: {
    label: 'Machine Learning',
    value: 4,
  },
  beam: {
    label: "Beam",
    value: 5,
    image: Beam,
  },
  airflow: {
    label: "Airflow",
    value: 5,
    image: Airflow,
  },
  nlp: {
    label: 'NLP',
    value: 4,
  },
  textClassification: {
    label: 'Text Classification',
    value: 3,
  },
  ner: {
    label: 'Named Entity Recognition',
    value: 3,
  },
  featureExtraction: {
    label: 'Feature Extraction',
    value: 3,
  },
  neuralNetworks: {
    label: 'Neural Networks',
    value: 3,
  },
  gpt: {
    label: 'GPT',
    value: 4,
  },
  deepLearning: {
    label: 'Deep Learning',
    value: 3,
  },
  bi: {
    label: 'Business Intelligence',
    value: 3,
  },
  dataStudio: {
    label: 'Google Data Studio',
    value: 3,
  },
  tableau: {
    label: 'Tableau',
    value: 3,
  },
}

Object.keys(tech).forEach((key, i) => {
  tech[key].id = `${i}`;
})

const techStackEdges = [
  [tech.techstack, tech.backend, 200],
  [tech.techstack, tech.frontend, 200],
  [tech.techstack, tech.devops, 200],
  [tech.techstack, tech.dataScience, 200],

  [tech.nlp, tech.dataScience],
  [tech.ml, tech.dataScience],
  [tech.textClassification, tech.nlp, 200],
  [tech.ner, tech.nlp],
  [tech.featureExtraction, tech.nlp],
  [tech.neuralNetworks, tech.ml],
  [tech.gpt, tech.neuralNetworks],
  [tech.deepLearning, tech.ml],

  [tech.bi, tech.dataScience, 300],
  [tech.dataStudio, tech.bi],
  [tech.tableau, tech.bi],

  [tech.beam, tech.dataEngineering],
  [tech.airflow, tech.dataEngineering],

  [tech.dataEngineering, tech.dataScience, 250],
  [tech.backend, tech.dataScience, 250],
  [tech.backend, tech.devops, 250],
  [tech.frontend, tech.devops, 250],
  [tech.dataScience, tech.frontend, 250],

  [tech.mssql, tech.relational],
  [tech.mysql, tech.relational],
  [tech.posgresql, tech.relational],
  [tech.relational, tech.db],
  [tech.nonRelational, tech.db],
  [tech.mongodb, tech.nonRelational],
  [tech.es, tech.nonRelational],
  [tech.redis, tech.nonRelational],
  [tech.datastore, tech.nonRelational],
  [tech.db, tech.backend, 200],

  [tech.css3, tech.frontend],
  [tech.html5, tech.frontend],
  [tech.python, tech.backend],
  [tech.php, tech.backend],
  [tech.node, tech.backend],
  [tech.csharp, tech.backend],
  [tech.csharp, tech.dotnet],
  [tech.ts, tech.frontend],
  [tech.js, tech.frontend],
  [tech.ts, tech.js],
  [tech.react, tech.ts],
  [tech.react, tech.ts],
  [tech.reactQuery, tech.react],
  [tech.reactHookForm, tech.react],
  [tech.materialUI, tech.react],
  [tech.django, tech.python],
  [tech.flask, tech.python],
  [tech.fastapi, tech.python],
  [tech.falcon, tech.python],
  [tech.express, tech.node],
  [tech.ci, tech.devops],
  [tech.cd, tech.devops],
  [tech.serverless, tech.devops],
  [tech.microservices, tech.devops],
  [tech.infrastructure, tech.devops],
  [tech.aws, tech.infrastructure],
  [tech.gcp, tech.infrastructure],
  [tech.firebase, tech.gcp],
  [tech.azure, tech.infrastructure],
  [tech.wordpress, tech.php],
  [tech.magento, tech.php],
  [tech.woocommerce, tech.wordpress],
];

export const generateNetworkChartData = (edges: typeof techStackEdges) => {
  return {
    nodes: edges.reduce((prev, [from, to]) => {
      if(!prev.find(({ id }) => id === from.id)) {
        prev.push(from);
      }
      if(!prev.find(({ id }) => id === to.id)) {
        prev.push(to);
      }
      return prev;
    }, []).map(node => ({
      ...node,
      ...(node?.image ? { shape: 'circularImage', image: node.image, imagePadding: 5 } : { shape: 'dot' }),
      ...(node?.value && { mass: node?.value / 3 }),
      value: 5,
    })),
    edges: edges.map(([from, to, length], id) => ({id, from: from.id, to: to.id, length})),
  };
}

export const data = generateNetworkChartData(techStackEdges);

const standardBackend = [
  [tech.backend, tech.python],
  [tech.python, tech.django],
  [tech.backend, tech.db],
  [tech.db, tech.posgresql],
  [tech.django, tech.posgresql],
]

const standardFrontend = [
  [tech.frontend, tech.ts],
  [tech.frontend, tech.html5],
  [tech.frontend, tech.css3],
  [tech.ts, tech.html5],
  [tech.ts, tech.react],
  [tech.html5, tech.css3],
  [tech.react, tech.reactQuery],
  [tech.react, tech.reactHookForm],
  [tech.react, tech.materialUI],
]

const standardDevops = [
  [tech.devops, tech.gcp],
  [tech.devops, tech.ci],
  [tech.devops, tech.cd],
  [tech.devops, tech.microservices],
  [tech.devops, tech.serverless],
  [tech.gcp, tech.firebase],
]

const standardBase = [
  [tech.frontend, tech.devops, 200],
  [tech.dataScience, tech.backend, 200],
  [tech.dataScience, tech.devops, 200],
  [tech.frontend, tech.backend, 200],
  [tech.techstack, tech.backend, 200],
  [tech.techstack, tech.devops, 200],
  [tech.techstack, tech.frontend, 200],
  [tech.techstack, tech.dataScience, 200],
]

const standardTechStack = [
  ...standardBase,
  ...standardBackend,
  ...standardFrontend,
  ...standardDevops,
]

export const clientTechStack = {
  ih: generateNetworkChartData([
    ...standardTechStack,
    [tech.backend, tech.node],
    [tech.express, tech.node],
    [tech.bi, tech.dataScience],
    [tech.dataStudio, tech.bi],
    [tech.db, tech.redis],
  ]),
  sp: generateNetworkChartData([
    ...standardTechStack,
    [tech.backend, tech.node],
    [tech.db, tech.es],
    [tech.bi, tech.dataScience],
    [tech.dataStudio, tech.bi],
  ]),
  rr: generateNetworkChartData([
    ...standardTechStack,
    [tech.backend, tech.node],
    [tech.backend, tech.csharp],
    [tech.csharp, tech.dotnet],
    [tech.db, tech.mongodb],
    [tech.db, tech.es],
    [tech.db, tech.mssql],
    [tech.bi, tech.dataScience],
    [tech.dataStudio, tech.bi],
    [tech.devops, tech.azure],
  ].filter(([from, to]) => ![from.id, to.id].includes(tech.firebase.id))),
  cat: generateNetworkChartData([
    ...standardTechStack,
  ].filter(([from, to]) => ![from.id, to.id].includes(tech.firebase.id))),
  tappr: generateNetworkChartData([
    ...standardTechStack,
    [tech.backend, tech.node],
    [tech.express, tech.node],
  ]),
  freeday: generateNetworkChartData([
    ...standardTechStack,
    [tech.ml, tech.dataScience],
    [tech.nlp, tech.dataScience],
  ]),
  cx: generateNetworkChartData([
    ...standardTechStack,
    [tech.ml, tech.dataScience],
    [tech.nlp, tech.dataScience],
  ]),
  lexr: generateNetworkChartData([
    ...standardTechStack,
  ]),
  greencast: generateNetworkChartData([
    ...standardTechStack,
  ]),
  cla: generateNetworkChartData([
    ...standardTechStack,
  ]),
  glopal: generateNetworkChartData([
    ...standardBase,
    [tech.devops, tech.gcp],
    [tech.devops, tech.aws],
    [tech.backend, tech.php],
    [tech.dataScience, tech.db],
    [tech.mongodb, tech.db],
    [tech.redis, tech.db],
    [tech.frontend, tech.html5],
    [tech.frontend, tech.css3],
    [tech.frontend, tech.js],
    [tech.php, tech.frontend],
  ]),
  'my-limo': generateNetworkChartData([
    ...standardBase,
    [tech.devops, tech.aws],
    [tech.backend, tech.php],
    [tech.dataScience, tech.db],
    [tech.mysql, tech.db],
    [tech.frontend, tech.html5],
    [tech.frontend, tech.css3],
    [tech.frontend, tech.js],
    [tech.php, tech.frontend],
  ]),
  etoilepedia: generateNetworkChartData([
    ...standardBase,
    [tech.devops, tech.aws],
    [tech.backend, tech.php],
    [tech.dataScience, tech.db],
    [tech.mysql, tech.db],
    [tech.frontend, tech.html5],
    [tech.frontend, tech.css3],
    [tech.frontend, tech.js],
    [tech.php, tech.frontend],
  ]),
  mi: generateNetworkChartData([
    ...standardTechStack,
    [tech.db, tech.mongodb],
  ]),
  ar: generateNetworkChartData([
    ...standardTechStack,
  ]),
  rb: generateNetworkChartData([
    ...standardTechStack,
  ]),
  ox: generateNetworkChartData([
    ...standardTechStack,
  ]),
  mp: generateNetworkChartData([
    ...standardTechStack,
    [tech.ml, tech.dataScience],
    [tech.nlp, tech.dataScience],
    [tech.gpt, tech.dataScience],
  ]),
  thr: generateNetworkChartData([
    ...standardTechStack,
    [tech.ml, tech.dataScience],
    [tech.nlp, tech.dataScience],
    [tech.gpt, tech.dataScience],
    [tech.react, tech.reactNative],
    [tech.react, tech.capacitor],
  ]),
}
