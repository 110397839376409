import React from 'react';

import bg from 'shared/img/bg.svg';


export const Background = () => {

  return (
    <div
      id="cvt-bg"
      style={{
        position: 'fixed',
        left: '-5vw',
        top: '2vh',
        width: '110vw',
        height: '110vh',
        zIndex: 1,
        opacity: 0.4,
        backgroundImage: `url(${bg})`,
        backgroundSize: 'contain',
        backgroundPosition: 'center',
        backgroundRepeat: 'no-repeat',
      }}
    />
  );
};
