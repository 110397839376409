import React, { forwardRef, useContext, useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Grid } from '@mui/material';

import { BaseSection } from 'shared/sections/BaseSection/BaseSection';
import { SectionTitle } from 'shared/sections/SectionTitle/SectionTitle';
import { LocalizationContext } from 'contexts/LocalizationContext/LocalizationContext';
import { portfolioData } from 'data/portfolio.data';
import { PortfolioFilterAutocomplete } from 'shared/components/PortfolioFilterAutocomplete/PortfolioFilterAutocomplete';
import { LayoutContext } from 'contexts/LayoutContext/LayoutContext';
import { PortfolioSectionItem } from 'shared/sections/PortfolioSection/PortfolioSectionItem';
import { MenuItemElement } from 'contexts/LayoutContext/LayoutContext.types';
import { CarouselContainer } from 'shared/components/Carousel/CarouselContainer';

const showFilters = false;

type PortfolioSectionProps = {
  autoUrlChange?: boolean;
}

export const PortfolioSection = forwardRef<MenuItemElement, PortfolioSectionProps>(({ autoUrlChange = true }, ref) => {
  const navigate = useNavigate();
  const [filters, setFilters] = useState<{ key: string; label: string, type: string }[]>([]);

  const clients = useMemo(() => {
    const cityFilters = filters.filter(({ type }) => type === 'city');
    const featureFilters = filters.filter(({ type }) => type === 'feature');
    const technologyFilters = filters.filter(({ type }) => type === 'technology');
    return portfolioData.filter(({ location, featureKeys = [], techStackLabels = [] }) => {
      let matches = true;
      if(cityFilters.length > 0) {
        matches = cityFilters.map(({ key }) => key).includes(location.city);
      }
      if(matches && featureFilters.length > 0) {
        matches = featureFilters.map(({ key }) => key).every(key => featureKeys.includes(key));
      }
      if(matches && technologyFilters.length > 0) {
        matches = technologyFilters.map(({ label }) => label).every(label => techStackLabels.includes(label));
      }
      return matches;
    });
  }, [filters]);

  const { dictionary } = useContext(LocalizationContext);
  const { isMobile } = useContext(LayoutContext);

  return (
    <BaseSection ref={ref}>
      <Grid item style={{width: '100%', textAlign: 'center'}}>
        <SectionTitle onEnterViewport={() => autoUrlChange && navigate('/portfolio')}>
          {dictionary.portfolioSectionTitle}
        </SectionTitle>
        <br/>
        {showFilters && <PortfolioFilterAutocomplete value={filters} onChange={(e: any, value: any) => setFilters(value)}/>}
      </Grid>
      <Grid item>
        <CarouselContainer>
          <Grid container alignItems="flex-start" wrap="nowrap" spacing={isMobile ? 3 : 6}>
            {clients.map((client, index) => (
              <PortfolioSectionItem {...client} key={index}/>
            ))}
          </Grid>
        </CarouselContainer>
      </Grid>
    </BaseSection>
  );
});
