import React, { useContext, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Prism as SyntaxHighlighter } from 'react-syntax-highlighter';
import { darcula } from 'react-syntax-highlighter/dist/esm/styles/prism';
import { Alert, Button, Chip, CircularProgress, Container, Paper, Typography } from '@mui/material';
import { Event, Science } from '@mui/icons-material';

import { routes } from 'config';
import { LocalizationContext } from 'contexts/LocalizationContext/LocalizationContext';


const sampleProduct = {
  'title': 'Samsung Galaxy S23 Ultra',
  'colors': ['red', 'black', 'blue', 'ocean'],
  'price_eur': 1399.30,
  'attributes': ['IP68', 'Gorilla Glass'],
}
const sampleProductWithId = {
  id: 1,
  ...sampleProduct,
}
const sampleOrder = {
  date: '2023-01-01T00:00:00.000',
  customer: {
    name: 'John Smith',
    email: 'john.smith@example.com',
  },
  products: [{id: 1, selectedColor: 'red'}]
}
const sampleOrderResponse = {
  id: 1,
  ...sampleOrder,
  products: [{...sampleOrder.products[0], product: sampleProductWithId }]
}

const baseUrl = 'https://beta-api.cvt.ai/v1';
const baseApiUrl = `${baseUrl}/api`;
const sampleApiKey = Math.random().toString(36).slice(2);
const sampleSearchUrl = `GET ${baseApiUrl}/products/?search=Samsun&size=10&offset=0&api-key=${sampleApiKey}`
const sampleFilterUrl = `GET ${baseApiUrl}/products/?price_eur__lt=1500&attributes__in=IP68&colors__nin=red&api-key=${sampleApiKey}`
const sampleUrl = (entity: string, method: string) => `${method} ${baseApiUrl}/${entity}/?api-key=${sampleApiKey}`;

export const RequestSnippet = ({ entity, jsonData, method, showUrl = true }: { entity?: string, jsonData?: any, method: string, showUrl?: boolean }) => {
  return (
    <Typography variant="body1">
      <SyntaxHighlighter language="bash" style={darcula}>
        {(showUrl && entity) ? sampleUrl(entity, method) : method}
      </SyntaxHighlighter>
      {jsonData && (
        <SyntaxHighlighter language="json" style={darcula}>
          {JSON.stringify(jsonData, null, 2)}
        </SyntaxHighlighter>
      )}
    </Typography>
  )
}

export const AIAPI = () => {

  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const { dictionary } = useContext(LocalizationContext);

  return (
    <Container maxWidth="lg" sx={{ mt: 12, textAlign: 'center' }}>
      <Typography variant="h3" color="primary">CVT AI API</Typography>
      <Chip icon={<Science/>} label="CVT Labs" sx={{ mt: 2 }} onClick={() => navigate(routes.labs)}/>
      <Paper sx={{ p: 2, mt: 4, mb: 4, textAlign: 'left' }}>

        <Typography variant="h5" sx={{ mt: 2 }}>Introduction:</Typography>
        <Typography variant="body1" sx={{ mt: 1 }} fontWeight={300}>CVT AI API is a beta prototype of an all purpose AI driven CRUD API that tries to behave as it should based on your request url, method and body. Powered by GPT-4 and using Django REST Framework Standards we have developed it to validate whether it can speed up our prototyping work in validating some assumptions before implementing a full fledged frontend + backend feature.</Typography>

        <Typography variant="h5" sx={{ mt: 2 }}>Not entirely a viable use-case (yet!)</Typography>
        <Typography variant="body1" sx={{ mt: 1 }} fontWeight={300}>
          We believe this experiment is not yet entirely needed or practical because it is:<br/>
          <ol>
            <li><b>Trying to solve a Deterministic Problem:</b> CRUD API functionality is rather deterministic meaning even an all-purpose CRUD api can be implemented with a relatively small number of intelligently crafted rule based abstract implementations.</li>
            <li><b>Slow:</b> APIs, especially CRUD ones, need to be extremely fast, this is something LLMs haven't achieved (yet!)</li>
            <li><b>99% Consistent:</b> APIs need to be consistent and reliable, they must produce a predictable, consistent and reliable response 100% of the time, While our tests have shown 99% reliability especially on simple CRUD operations it is not yet 100%</li>
          </ol>
          However our idea with this experiment is to simply showcase the future potential of LLMs, if the speed and token limitations will significantly improve (and we most definitely predict they will) then the use case becomes more practical. Especially considering the ability to add additional domain specific constraints which would futher increase the reliability and consistency of responses.
        </Typography>

        <Typography variant="h5" sx={{ mt: 4 }}>Limitations:</Typography>
        <Alert severity="warning" sx={{ textAlign: 'left', mt: 1  }}>The API is for prototyping purposes only, it will be very slow and supports a very limited size of db (50% of the max tokens allowed by OpenAI's GPT-4 models)</Alert>
        <Alert severity="error" sx={{ textAlign: 'left', mt: 1  }}>Do not use it for production environments, storing sensitive data or if you need to work with large datasets</Alert>

        <Typography variant="h5" sx={{ mt: 4 }}>Quickstart:</Typography>
        <Alert severity="success" sx={{ mt: 1 }}>Here's a test API key for you to use</Alert>
        <Typography variant="body1">
          <SyntaxHighlighter language="bash" style={darcula}>
            {sampleApiKey}
          </SyntaxHighlighter>
        </Typography>

        <Typography variant="h5" sx={{ mt: 4 }}>Creating new objects</Typography>
        <Alert severity="warning" sx={{ mt: 1 }}>The sample objects / entities we are using are just an example, you can literally use any object, entity or schema you can possibly imagine.</Alert>
        <Alert severity="info" sx={{ mt: 1 }}>Now try making a request like the following one, you can rename the entity to whatever you want and change the object as you wish</Alert>
        <RequestSnippet jsonData={sampleProduct} method="POST" entity="products" />
        <Alert severity="success" sx={{ mt: 1 }}>The api will recognize your object, give it a primary key and save it in the database as well as reponding with the new entity.</Alert>
        <RequestSnippet jsonData={sampleProductWithId} method="200" showUrl={false}/>

        <Typography variant="h5" sx={{ mt: 4 }}>Handling Relations</Typography>
        <Alert severity="info" sx={{ mt: 1 }}>The AI should support foreign keys and relations so you can now create an order that references the product</Alert>
        <RequestSnippet jsonData={sampleOrder} method="POST" entity="orders" />
        <Alert severity="success" sx={{ mt: 1 }}>The api will recognize your object, and if possible it will know the related object, most of the time it will even include it as nested in the response.</Alert>
        <RequestSnippet jsonData={sampleOrderResponse} method="200" showUrl={false}/>

        <Typography variant="h5" sx={{ mt: 4 }}>CRUD Operations</Typography>
        <Alert severity="info" sx={{ mt: 1 }}>Now that the API has an entity, it will be ready to accept calls to endpoints such as (but not limited to):</Alert>
        {[
          ['products/stats', 'GET'],
          ['products/count', 'GET'],
          ['products', undefined],
          ['products/{pk}', undefined],
        ].map(([entity, methods = 'GET | POST | PUT | PATCH | DELETE']: any, i) => (
          <RequestSnippet key={i} method={methods} entity={entity}/>
        ))}

        <Typography variant="h5" sx={{ mt: 4 }}>Filters, Search & Pagination</Typography>
        <Alert severity="info" sx={{ mt: 1 }}>The AI will try to support search, filters and pagination using Python's Django REST Framework Standards</Alert>
        <SyntaxHighlighter language="bash" style={darcula}>
          {sampleSearchUrl}
        </SyntaxHighlighter>
        <SyntaxHighlighter language="bash" style={darcula}>
          {sampleFilterUrl}
        </SyntaxHighlighter>

        <Typography variant="h5" sx={{ mt: 4 }}>Get Creative!</Typography>
        <Alert severity="warning" sx={{ mt: 1 }}>Given that the AI is running on an LLM your imagination is the only real limit when it comes to endpoints you can try to use, here's some crazy examples:</Alert>
        {[
          ['products/create-5-additional-example-products', 'POST'],
          ['products/hide-nested-attributes', 'GET'],
          ['products/enrich-with-description', 'POST'],
          ['products/delete-all-prices-with-prices-above-100', 'DELETE'],
          ['products/{whatever-you-want}', 'POST'],
        ].map(([entity, methods = 'GET | POST | PUT | PATCH | DELETE']: any, i) => (
          <RequestSnippet key={i} method={methods} entity={entity}/>
        ))}

        <Typography variant="h5" sx={{ mt: 4 }}>Error Handling & Status Codes:</Typography>
        <Alert severity="error" sx={{ mt: 1 }}>The AI will try to generate standard HTTP status codes and verbose messages</Alert>
        <RequestSnippet method="DELETE" entity="products/132"/>
        <RequestSnippet method="404" jsonData={{ error: 'Product 132 does not exist' }}/>

        <Typography variant="h5" sx={{ mt: 4 }}>Full control over the entire DB</Typography>
        <Alert severity="success" sx={{ mt: 1 }}>Our API reserved a db endpoint and allows you to fully manipulate it and control it. You can extend or fix some unwanted behaviour. You can also delete it entirely if necessary.</Alert>
        <RequestSnippet method="GET | POST | PUT | PATCH | DELETE" entity="db"/>

        <Typography variant="h5" sx={{ mt: 4 }}>AI Generated Documentation</Typography>
        <Alert severity="info" sx={{ mt: 1 }}>The AI will try to generate documentation using openapi standards to the best of its abilities, you can access them here:</Alert>
        <RequestSnippet method={`${baseUrl}/swagger/?api-key=${sampleApiKey}`}/>

        <Typography variant="h5" sx={{ mt: 4 }}>Liability:</Typography>
        <Typography variant="body1" sx={{ mt: 1, mb: 2 }} fontWeight={300}>
          CVT will not be held liable for any issues deriving from the use of this experimental tool including but not limited to data privacy, speed, reliability, or damages caused by using this tool in a non experimental way. For any further info please write us at info@cvt.services
        </Typography>
      </Paper>
      <Typography variant="h5" sx={{ mt: 4 }}>Do you want to learn more or have a chat about it?</Typography>
      <Button
        color="secondary"
        variant="contained"
        size="large"
        sx={{textTransform: 'none', mt: 2, mb: 8 }}
        startIcon={loading ? <CircularProgress style={{ width: 24, height: 24 }}/> : <Event/>}
        onClick={() => {
          //@ts-ignore
          window.lintrk('track', { conversion_id: 11423801 });
          setLoading(true);
          setTimeout(() => {
            window.open('https://meetings.hubspot.com/my11', '_blank');
            setLoading(false);
          }, 2000);
        }}
        disabled={loading}
      >
        <Typography variant="h6">
          {dictionary.homeSectionCTAText}
        </Typography>
      </Button>
    </Container>
  )
}
