import React, { FC } from 'react';
import { Box, styled, Typography } from '@mui/material';
import ThumbDownIcon from '@mui/icons-material/ThumbDown';
import ThumbUpIcon from '@mui/icons-material/ThumbUp';

import { WhySectionComparisonItemDivider } from 'shared/sections/WhySection/components/WhySectionComparisonItemDivider';


const ItemContainer = styled(Box)(() => ({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  width: '80%',
  minHeight: 49,

  '&:nth-child(even)': {
    background: 'linear-gradient(' +
      '270.07deg, ' +
      'rgba(99, 101, 255, 0) 0.07%, ' +
      'rgba(99, 101, 255, 0.08) 23.59%, ' +
      'rgba(128, 0, 212, 0.08) 57.24%, ' +
      'rgba(128, 0, 212, 0) 77.92%' +
    ');',
  },

  '&:nth-child(odd)': {
    background: 'linear-gradient(' +
      '270.07deg, ' +
      'rgba(99, 101, 255, 0) 0.07%, ' +
      'rgba(99, 101, 255, 0.03) 23.59%, ' +
      'rgba(128, 0, 212, 0.03) 57.24%, ' +
      'rgba(128, 0, 212, 0) 77.92%' +
    ');',
  },
}));

type Props = {
  pros: string;
  cons: string;
}

export const WhySectionComparisonItem: FC<Props> = ({
  pros,
  cons,
}) => {
  return (
    <ItemContainer>
      <Box flex={1} py={{ xs: 2, sm: 0 }}>
        <Typography align="right">{cons}</Typography>
      </Box>
      <Box display="flex" alignItems="center" justifyContent="center">
        <Box pl={4.5} pr={2}>
          <ThumbDownIcon
            sx={(theme) => ({
              width: 15,
              height: 15,
              color: theme.palette.common.lightGrey,
            })}
          />
        </Box>
        <Box>
          <WhySectionComparisonItemDivider/>
        </Box>
        <Box pr={4.5} pl={2}>
          <ThumbUpIcon
            sx={(theme) => ({
              width: 15,
              height: 15,
              color: theme.palette.common.electricViolet,
            })}
          />
        </Box>
      </Box>
      <Box flex={1} py={{ xs: 2, sm: 0 }}>
        <Typography align="left">{pros}</Typography>
      </Box>
    </ItemContainer>
  );
}