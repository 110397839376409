import React, { useContext } from 'react';
import { Step, StepLabel, Stepper, Tooltip, Typography } from '@mui/material';
import { styled } from '@mui/styles';
import { useLocation } from 'react-router-dom';

import { LayoutContext } from 'contexts/LayoutContext/LayoutContext';
import { ProgressBarStep } from 'shared/components/ProgressBar/ProgressBarStep';
import { ProgressBarConnector } from 'shared/components/ProgressBar/ProgressBarConnector';


const StyledDiv = styled('div')(({ theme }) => ({
  position: 'fixed',
  top: 50,
  left: 15,
  zIndex: 999,

  [theme.breakpoints.down('sm')]: {
    display: 'none',
  },
}))

export const ProgressBar = () => {
  const { pathname } = useLocation();
  const { menuItems } = useContext(LayoutContext);
  const activeIndex = menuItems.findIndex((item) => item.to === pathname);

  return (
    <StyledDiv>
      <Stepper
        nonLinear
        orientation="vertical"
        connector={<ProgressBarConnector/>}
      >
        {menuItems.map((menuItem, index) => {
          if(menuItem?.menuOnly) {
            return null;
          }
          return (
            <Step key={index}>
              <Tooltip title={<Typography variant="body1">{menuItem.name}</Typography>} placement="right">
                <StepLabel
                  StepIconComponent={ProgressBarStep}
                  StepIconProps={{
                    icon: index,
                    active: activeIndex === index,
                  }}
                />
              </Tooltip>
            </Step>
          )
        })}
      </Stepper>
    </StyledDiv>
  )
};
