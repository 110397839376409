import { Box } from '@mui/material';
import { styled } from '@mui/styles';


export const CarouselContainer = styled(Box)(({ theme }) => ({
  maxWidth: 'calc(100vw - 150px)',
  overflowX: 'auto',
  overflowY: 'hidden',
  whiteSpace: 'nowrap',

  [theme.breakpoints.down('sm')]: {
    maxWidth: 'calc(100vw - 20px)',
  },
}));
