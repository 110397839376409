import React, { FC } from 'react';
import { Box, styled, Typography } from '@mui/material';

import { novaFont } from 'styles/muiTheme';

const BoldText = styled(Typography)(() => ({
  display: 'inline',
  fontSize: 'inherit',
  fontFamily: 'inherit',
  fontWeight: 600,
  lineHeight: 1.5,
}));

export const WhySectionSubtitle: FC = () => {
  return (
    <Typography component={Box} variant="h5" fontFamily={novaFont} width={'80%'}>
      Because <BoldText>"Build it and they will come"</BoldText> is one of the main reasons <BoldText>most startups fail</BoldText>
    </Typography>
  )
};
