import React, { useContext } from 'react';
import { BrowserRouter as Router } from 'react-router-dom';
import { ThemeProvider, CssBaseline } from '@mui/material';

import { createTheme } from 'styles/muiTheme';
import { LayoutContext, LayoutContextProvider } from 'contexts/LayoutContext/LayoutContext';
import { LocalizationContextProvider } from 'contexts/LocalizationContext/LocalizationContext';
import { Nav, Menu, UserMenu } from 'shared/components';
import { Root } from 'views/Root';
import { Background } from 'shared/components/Background/Background';


const AppWithTheme = () => {

  const {useDarkTheme} = useContext(LayoutContext);

  const theme = createTheme(useDarkTheme);

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline/>
      <Background/>
      <Router>
        <div style={{position: 'relative', zIndex: 2}}>
          <Nav/>
          <Menu/>
          <UserMenu/>
          <Root/>
        </div>
      </Router>
    </ThemeProvider>
  )
}

const App = () => {
  return (
    <LocalizationContextProvider>
      <LayoutContextProvider>
        <AppWithTheme/>
      </LayoutContextProvider>
    </LocalizationContextProvider>
  );
}

export default App;
