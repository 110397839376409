import React, { ReactNode, forwardRef } from 'react';
import { Grid } from '@mui/material';
import { makeStyles } from '@mui/styles';

import { MenuItemElement } from 'contexts/LayoutContext/LayoutContext.types';

const useStyles = makeStyles(theme => ({
  root: {
    position: 'relative',
    minHeight: '100vh',
    width: '100vw',
    marginBottom: 100,
    textAlign: 'center',
    margin: 'auto',
  },
  content: {
    width: '100%',
    minHeight: '100vh',
    margin: 'auto',
    marginBottom: 100,
  },
}));

type Props = {
  children: ReactNode;
  style?: React.CSSProperties,
}

export const BaseSection = forwardRef<MenuItemElement, Props>((({ children, style }, ref) => {
  const classes = useStyles();

  return (
    <Grid ref={ref} item className={classes.root} style={style}>
      <Grid
        container
        direction="column"
        alignItems="center"
        justifyContent="center"
        className={classes.content}
      >
        {children}
      </Grid>
    </Grid>
  );
}))
