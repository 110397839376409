import marco from 'shared/img/team/marco.jpg';
import lukas from 'shared/img/team/lukas.jpeg';
import fabio from 'shared/img/team/fabio.webp';
import ashline from 'shared/img/team/ashline.webp';
import richard from 'shared/img/team/richard.webp';
import bartek from 'shared/img/team/bartek.webp';
import mateusz from 'shared/img/team/mateusz.webp';
import mikolaj from 'shared/img/team/mikolaj.webp';
import wouter from 'shared/img/team/wouter.webp';
import pedro from 'shared/img/team/pedro.webp';
import balbina from 'shared/img/team/balbina.jpg';
import ivan from 'shared/img/team/ivan.webp';
import alex from 'shared/img/team/alex.jpeg';
import daniil from 'shared/img/team/daniil.jpeg';
import piotr from 'shared/img/team/piotr.png';
import kyril from 'shared/img/team/kyril.jpeg';
import antonio from 'shared/img/team/antonio.jpeg';
import nikan from 'shared/img/team/nikan.jpg';
import angel from 'shared/img/team/angel.webp';


export const members = [
  {
    title: 'Marco',
    subtitle: 'CEO / Software Engineer',
    image: marco,
  },
  {
    title: 'Lukas',
    subtitle: 'Board Member',
    image: lukas,
  },
  {
    title: 'Richard',
    subtitle: 'Product Manager',
    image: richard,
  },
  {
    title: 'Wouter',
    subtitle: 'Product Manager',
    image: wouter,
  },
  {
    title: 'Angel',
    subtitle: 'Product Manager',
    image: angel,
  },
  {
    title: 'Mikolaj',
    subtitle: 'Technical Advisor',
    image: mikolaj,
  },
  {
    title: 'Fabio',
    subtitle: 'Software Engineer',
    image: fabio,
  },
  {
    title: 'Ashline',
    subtitle: 'Software Engineer',
    image: ashline,
  },
  {
    title: 'Pedro',
    subtitle: 'Software Engineer',
    image: pedro,
  },
  {
    title: 'Bartek',
    subtitle: 'Software Engineer',
    image: bartek,
  },
  {
    title: 'Mateusz',
    subtitle: 'Software Engineer',
    image: mateusz,
  },
  {
    title: 'Piotr',
    subtitle: 'Software Engineer',
    image: piotr,
  },
  {
    title: 'Antonio',
    subtitle: 'Software Engineer',
    image: antonio,
  },
  {
    title: 'Kyril',
    subtitle: 'Software Engineer',
    image: kyril,
  },
  {
    title: 'Alex',
    subtitle: 'Software Engineer',
    image: alex,
  },
  {
    title: 'Ivan',
    subtitle: 'Software Engineer',
    image: ivan,
  },
  {
    title: 'Dan',
    subtitle: 'Software Engineer',
    image: daniil,
  },
  {
    title: 'Nikan',
    subtitle: 'Software Engineer',
    image: nikan,
  },
  {
    title: 'Balbina',
    subtitle: 'Administration',
    image: balbina,
  },
]
