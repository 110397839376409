import ihLaptop from 'shared/img/screenshots/details/ih.webp';
import ihLaptopCompressed from 'shared/img/screenshots/laptop/ih.webp';
import ihMobile from 'shared/img/screenshots/mobile/ih.webp';
import ihLogo from 'shared/img/logos/ih.png';
import rrLaptop from 'shared/img/screenshots/details/rr.webp';
import rrLaptopCompressed from 'shared/img/screenshots/laptop/rr.webp';
import rrMobile from 'shared/img/screenshots/mobile/rr.webp';
import rrLogo from 'shared/img/logos/robin.svg';
import catLaptop from 'shared/img/screenshots/details/cat.webp';
import catLaptopCompressed from 'shared/img/screenshots/laptop/cat.webp';
import catLogo from 'shared/img/logos/cat.png';
import myLimoLogo from 'shared/img/logos/myLimoLogo.svg';
import myLimoLaptop from 'shared/img/screenshots/details/myLimo.webp';
import myLimoLaptopCompressed from 'shared/img/screenshots/laptop/myLimo.webp';
import etoilepediaLogo from 'shared/img/logos/etoilepediaLogo.svg';
import etoilepediaLaptop from 'shared/img/screenshots/details/etoilepedia.webp';
import etoilepediaLaptopCompressed from 'shared/img/screenshots/laptop/etoilepedia.webp';
import glopalLaptop from 'shared/img/screenshots/details/glopal.webp';
import glopalLaptopCompressed from 'shared/img/screenshots/laptop/glopal.webp';
import glopalLogo from 'shared/img/logos/glopal.svg';
import spLaptop from 'shared/img/screenshots/details/sp.webp';
import spLaptopCompressed from 'shared/img/screenshots/laptop/sp.webp';
import spMobile from 'shared/img/screenshots/mobile/sp.webp';
import spLogo from 'shared/img/logos/sp.png';
import lexrLaptop from 'shared/img/screenshots/details/lexr.webp';
import lexrLaptopCompressed from 'shared/img/screenshots/laptop/lexr.webp';
import lexrMobile from 'shared/img/screenshots/mobile/lexr.webp';
import lexrLogo from 'shared/img/logos/lexr.png';
import tapprLaptop from 'shared/img/screenshots/details/tappr.webp';
import tapprLaptopCompressed from 'shared/img/screenshots/laptop/tappr.webp';
import tapperLogo from 'shared/img/logos/tapprLogo.webp';
import fdLaptop from 'shared/img/screenshots/details/fd.webp';
import fdLaptopCompressed from 'shared/img/screenshots/laptop/fd.webp';
import fdMobile from 'shared/img/screenshots/mobile/fd.webp';
import fdLogo from 'shared/img/logos/freeday.svg';
import gcLogo from 'shared/img/logos/gcLogo.svg';
import gcLaptop from 'shared/img/screenshots/details/gc.webp';
import gcLaptopCompressed from 'shared/img/screenshots/laptop/gc.webp';
import gcMobile from 'shared/img/screenshots/mobile/gc.webp';
import cxLogo from 'shared/img/logos/cxLogo.svg';
import cxLaptop from 'shared/img/screenshots/details/cx.webp';
import cxLaptopCompressed from 'shared/img/screenshots/laptop/cx.webp';
import nlLogo from 'shared/img/logos/nlLogo.svg';
import nlLaptop from 'shared/img/screenshots/details/nl.webp';
import nlLaptopCompressed from 'shared/img/screenshots/laptop/nl.webp';
import nlMobile from 'shared/img/screenshots/mobile/nl.webp';
import claLogo from 'shared/img/logos/claLogo.svg';
import claLaptop from 'shared/img/screenshots/details/cla.webp';
import claLaptopCompressed from 'shared/img/screenshots/laptop/cla.webp';
import claMobile from 'shared/img/screenshots/mobile/cla.webp';
import rbLogo from 'shared/img/logos/robleLogo.svg';
import rbLaptop from 'shared/img/screenshots/details/rb.webp';
import rbLaptopCompressed from 'shared/img/screenshots/laptop/rb.webp';
import rbMobile from 'shared/img/screenshots/mobile/rb.webp';
import arLogo from 'shared/img/logos/arLogo.svg';
import arLaptop from 'shared/img/screenshots/details/ar.webp';
import arLaptopCompressed from 'shared/img/screenshots/laptop/ar.webp';
import arMobile from 'shared/img/screenshots/mobile/ar.webp';
import miLogo from 'shared/img/logos/miLogo.svg';
import miLaptop from 'shared/img/screenshots/details/mi.webp';
import miLaptopCompressed from 'shared/img/screenshots/laptop/mi.webp';
import miMobile from 'shared/img/screenshots/mobile/mi.webp';
import oxLogo from 'shared/img/logos/oxLogo.svg';
import oxLaptop from 'shared/img/screenshots/details/ox.webp';
import oxLaptopCompressed from 'shared/img/screenshots/laptop/ox.webp';
import oxMobile from 'shared/img/screenshots/mobile/ox.webp';
import mpLogo from 'shared/img/logos/mpLogo.webp';
import mpLaptop from 'shared/img/screenshots/details/mp.webp';
import mpLaptopCompressed from 'shared/img/screenshots/laptop/mp.webp';
import mpMobile from 'shared/img/screenshots/mobile/mp.webp';
import esLogo from 'shared/img/logos/esLogo.svg';
import esLaptop from 'shared/img/screenshots/details/es.webp';
import esLaptopCompressed from 'shared/img/screenshots/laptop/es.webp';
import thrLogo from 'shared/img/logos/thr-logo.png';
import thrLaptop from 'shared/img/screenshots/laptop/thr.png';
import thrMobile from 'shared/img/screenshots/mobile/thr.png';
import { features } from 'data/features.data';
import { locationsObject } from 'data/locations.data';

import { clientTechStack, generateNetworkChartData } from './techStack.data';

export type Client = {
  enabled: boolean;
  slug: string;
  title: string;
  client: string;
  location: typeof locationsObject.warsaw;
  users: string;
  industry: string;
  image: string;
  laptopImage: string;
  mobileImage?: string;
  logo?: string;
  features: { key: string; Icon: any }[],
  featureKeys?: string[],
  description: string;
  longDescription?: string;
  techStack?: ReturnType<typeof generateNetworkChartData>,
  techStackLabels?: string[];
}

export const portfolioData: Client[] = [
  {
    slug: 'thr',
    enabled: true,
    title: 'Thr',
    client: 'Thr Technologies',
    location: locationsObject.chicago,
    users: '1000+',
    industry: 'Social Media',
    image: thrLaptop,
    laptopImage: thrLaptop,
    mobileImage: thrMobile,
    logo: thrLogo,
    features: [
      features.sso,
      features.payments,
      features.userManagement,
      features.mobile,
      features.tablet,
      features.desktop,
      features.pwa,
      features.payWall,
      features.ml,
      features.nlp,
      features.maps,
      features.notifications,
      features.darkMode,
    ],
    description: 'A professional-social network that allows people that are crossing paths to meet and connect.',
    longDescription: 'Users can learn about possible friends and contacts that are crossing paths with them. They can also interact with them via direct or group messaging',
  },
  {
    slug: 'mp',
    enabled: true,
    title: 'Mentour Pilot',
    client: 'Mentour 60',
    location: locationsObject.barcelona,
    users: '100.000+',
    industry: 'Aviation-Tech',
    image: mpLaptop,
    laptopImage: mpLaptopCompressed,
    mobileImage: mpMobile,
    logo: mpLogo,
    features: [
      features.sso,
      features.payments,
      features.userManagement,
      features.mobile,
      features.tablet,
      features.desktop,
      features.pwa,
      features.payWall,
      features.ml,
      features.nlp,
      features.maps,
      features.video,
      features.notifications,
      features.darkMode,
    ],
    description: 'An AI drivenCloud PWA for aviation enthusiasts as well as passengers with fear of flying. An innovative discovery of AI in Aviation.',
    longDescription: 'Users can interact with a GPT Powered AI bot that is crafted and trained specifically for aviation, they can also browse aviation content such as news, videos and flights and interact with the chatbot about the content.',
  },
  {
    slug: 'es',
    enabled: true,
    title: 'Sous',
    client: 'Sous',
    location: locationsObject.amsterdam,
    users: '10.000+',
    industry: 'Food-Tech',
    image: esLaptop,
    laptopImage: esLaptopCompressed,
    logo: esLogo,
    features: [
      features.sso,
      features.payments,
      features.userManagement,
      features.mobile,
      features.tablet,
      features.desktop,
      features.maps,
      features.ecommerce,
      features.darkMode,
      features.accounting,
      features.searchEngine,
      features.tasks,
    ],
    description: 'A cloud app that enables Sous to deliver partially prepared meals from top chefs in the Netherlands.',
    longDescription: 'Users can manage & track orders as well as ensuring the operations of the end to end user journey are smooth and valuable.',
  },
  {
    slug: 'freeday',
    enabled: true,
    title: 'Freeday',
    client: 'Freeday',
    location: locationsObject.rotterdam,
    users: '100+',
    industry: 'AI',
    image: fdLaptop,
    laptopImage: fdLaptopCompressed,
    mobileImage: fdMobile,
    logo: fdLogo,
    features: [
      features.userManagement,
      features.accounting,
      features.ml,
      features.nlp,
      features.teamManagement,
      features.reports,
      features.sso,
      features.mobile,
      features.tablet,
      features.desktop,
      features.notifications,
      features.darkMode,
    ],
    description: 'An AI app that provides automation for chats and emails',
    longDescription: 'An AI app that provides automation for chats and emails',
  },
  {
    slug: 'rb',
    enabled: true,
    title: 'Roble',
    client: 'Roble',
    location: locationsObject.austin,
    users: 'N/A',
    industry: 'Green-Tech',
    image: rbLaptop,
    laptopImage: rbLaptopCompressed,
    mobileImage: rbMobile,
    logo: rbLogo,
    features: [
      features.sso,
      features.payments,
      features.documentManagement,
      features.userManagement,
      features.teamManagement,
      features.reports,
      features.projects,
      features.tasks,
      features.mobile,
      features.tablet,
      features.desktop,
    ],
    description: 'A cloud application that allows property management companies to monitor and visualise the carbon emissions of their properties.',
    longDescription: 'Users can configure their various property portfolios and sync them with utility bills which are then used to derive emissions insights as well as suggestions on how to offset or reduce them.',
  },
  {
    slug: 'greencast',
    enabled: true,
    title: 'Coolset',
    client: 'Coolset BV',
    location: locationsObject.amsterdam,
    users: '50-100',
    industry: 'Environmental-Tech',
    image: gcLaptop,
    laptopImage: gcLaptopCompressed,
    mobileImage: gcMobile,
    logo: gcLogo,
    features: [
      features.ml,
      features.nlp,
      features.searchEngine,
      features.reports,
      features.teamManagement,
      features.sso,
      features.mobile,
      features.tablet,
      features.desktop,
      features.video,
    ],
    description: 'An ML powered cloud app that provides visibility and transparency about Emissions',
    longDescription: 'An ML powered cloud app that provides visibility and transparency about Emissions. Companies can connect their accounting software which will securely sync transactions and automatically classify them to emission factors. The app provides granular reports and information about a company\'s emissions and allows them to offset those emissions.',
  },
  {
    slug: 'sp',
    enabled: true,
    title: 'The SalesPlaybook App',
    client: 'SalesPlaybook AG',
    location: locationsObject.zurich,
    users: '50-100',
    industry: 'Sales',
    image: spLaptop,
    laptopImage: spLaptopCompressed,
    mobileImage: spMobile,
    logo: spLogo,
    features: [
      features.searchEngine,
      features.ecommerce,
      features.projects,
      features.reports,
      features.teamManagement,
      features.pwa,
      features.sso,
      features.darkMode,
      features.multiLanguage,
      features.multiCurrency,
      features.mobile,
      features.tablet,
      features.desktop,
      features.chromeExtension,
      features.dataMining,
      features.video,
    ],
    description: 'A sales acceleration app helping committed founders to establish and execute their sales growth plan. ',
    longDescription: 'Users are able to access e-lessons with videos in German, Swiss German and English. They are able to track their progress throughout their learning journey, manage their subscriptions, purchase products via our full multi-language and multi-currency e-commerce implementation. Customers can also browse between a variety of coaches and book meetings directly from the app. They can also use a talent search engine providing qualified sales talent in Switzerland. Users can sign up with their Google Accounts via SSO and it provides accessibility features such as dark mode and is fully accessible on desktop tablets and mobile.',
  },
  {
    slug: 'rr',
    enabled: true,
    title: 'Recruit Robin',
    client: 'Recruit Robin',
    location: locationsObject.utrecht,
    users: '10.000+',
    industry: 'HRTech',
    image: rrLaptop,
    laptopImage: rrLaptopCompressed,
    mobileImage: rrMobile,
    logo: rrLogo,
    features: [
      features.searchEngine,
      features.userManagement,
      features.teamManagement,
      features.reports,
      features.payWall,
      features.notifications,
      features.projects,
      features.multiLanguage,
      features.chromeExtension,
      features.mobile,
      features.tablet,
      features.desktop,
    ],
    description: 'A talent sourcing platform for the Netherlands Market. It aggregates candidates from multiple job boards and makes them searchable from a single app.',
    longDescription: 'We were given the challenge to refactor Recruit Robin’s HR-Tech recruitment platform. A “Sky-Scanner” for recruitment in the Netherlands of some sort. Users are able to search for talent from 10 different platforms while remaining on a single app by specifying dozens of filters to provide accurate and relevant results. The app is available in English and dutch. It includes integrations with several ATS providers as well as platforms such as Linkedin, Indeed, Monster and many more. Users are able to create campaigns, approve and reject candidates throughout their recruitment pipelines and push the approved candidates directly in their ATS thanks to our powerful integrations. Users can set up notifications to be informed when new, relevant candidates have been found allowing them to be the first to reach out to them. The app is fully accessible on desktop tablets and mobile. The technologies used are: React, Typescript, Material UI, NodeJS, C#, .NET, Django, Python, PostgreSQL, ElasticSearch, MongoDB, Google App Engine and Cloudflare.'
  },
  {
    slug: 'naala',
    enabled: true,
    title: 'Naala App',
    client: 'Naala BV',
    location: locationsObject.rotterdam,
    users: '50-100',
    industry: 'Compliance-Tech',
    image: nlLaptop,
    laptopImage: nlLaptopCompressed,
    mobileImage: nlMobile,
    logo: nlLogo,
    features: [
      features.ml,
      features.nlp,
      features.searchEngine,
      features.reports,
      features.teamManagement,
      features.sso,
      features.mobile,
      features.tablet,
      features.desktop,
      features.video,
    ],
    description: 'An ML powered cloud app that provides visibility and transparency about Emissions',
    longDescription: 'An ML powered cloud app that provides visibility and transparency about Emissions. Companies can connect their accounting software which will securely sync transactions and automatically classify them to emission factors. The app provides granular reports and information about a company\'s emissions and allows them to offset those emissions.',
  },
  {
    slug: 'lexr',
    enabled: true,
    title: 'LEXR App',
    client: 'LEXR AG',
    location: locationsObject.zurich,
    users: '50-100',
    industry: 'Law-Tech',
    image: lexrLaptop,
    laptopImage: lexrLaptopCompressed,
    mobileImage: lexrMobile,
    logo: lexrLogo,
    features: [
      features.searchEngine,
      features.projects,
      features.tasks,
      features.calendar,
      features.reports,
      features.teamManagement,
      features.pwa,
      features.sso,
      features.darkMode,
      features.multiLanguage,
      features.mobile,
      features.tablet,
      features.desktop,
      features.video,
    ],
    description: 'A cloud app that provides visibility and transparency to LEXR clients about work in progress, planned and completed',
    longDescription: 'A cloud app developed to provide visibility and remove friction between LEXR legal counsels and their clients. Users can access the app via Google or Microsoft SSO and gain visibility into all tasks, their status and progress. They are also able to control their legal costs and request for ad-hoc tasks directly from the app. The application also provides back-office management features allowing all LEXR counsels to have a centralized source of truth to manage their portfolio as well as collaborate with other counsels and customers to ensure a smooth and frictionless experience. This includes but is not limited to features such as calendars, notifications, activity logs, access to resources and template generators for common legal documents required by clients. The app provides accessibility features such as PWA, dark mode and is responsive to mobile devices, tablets, laptops and desktops. It is available in English and German.',
  },
  {
    slug: 'cat',
    enabled: true,
    title: 'CAT - HR Survey App',
    client: 'Caterpillar',
    location: locationsObject.warsaw,
    users: 'N/A',
    industry: 'HRTech',
    image: catLaptop,
    laptopImage: catLaptopCompressed,
    logo: catLogo,
    features: [
      features.projects,
      features.reports,
      features.teamManagement,
      features.userManagement,
      features.mobile,
      features.tablet,
      features.desktop,
    ],
    description: 'An HR app providing Surveys to collect and analyze internal HR metrics.',
    longDescription: 'An HR tech cloud-application enabling HR to create and execute performance reports via surveys that caterpillar employees can fill out from their phone, tablet or computer. The surveys support multiple levels allowing for a bottom to top flow of information which is then explorable via charts and other types of reports. Users can sign in via a customized Microsoft SSO integration. The app is only available in polish as it is used by the polish branches of Caterpillar, a fortune 1000 company.',
  },
  {
    slug: 'cla',
    enabled: true,
    title: 'CLA',
    client: 'CLA',
    location: locationsObject.amsterdam,
    users: '100.000+',
    industry: 'Labour-Tech',
    image: claLaptop,
    laptopImage: claLaptopCompressed,
    mobileImage: claMobile,
    logo: claLogo,
    features: [
      features.documentManagement,
      features.darkMode,
      features.teamManagement,
      features.userManagement,
      features.mobile,
      features.tablet,
      features.desktop,
    ],
    description: 'A document management app geared towards Collective Labour Agreements in the Netherlands.',
    longDescription: 'A document management app geared towards Collective Labour Agreements in the Netherlands.',
  },
  {
    slug: 'ar',
    enabled: true,
    title: 'AR',
    client: 'AR Solutions',
    location: locationsObject.warsaw,
    users: '10+',
    industry: 'Culinary',
    image: arLaptop,
    laptopImage: arLaptopCompressed,
    mobileImage: arMobile,
    logo: arLogo,
    features: [
      features.accounting,
      features.payments,
      features.sso,
      features.documentManagement,
      features.userManagement,
      features.teamManagement,
      features.reports,
      features.projects,
      features.tasks,
      features.mobile,
      features.tablet,
      features.desktop,
      features.ml,
    ],
    description: 'A cloud app that allows restaurant owners to purchase products at more competitive prices.',
    longDescription: 'Users can upload their invoices which are then split into product costs, the application provides suggestions on how to reduce costs on the same products by buying from different suppliers or requesting better conditions based on market benchmarks.',
  },
  {
    slug: 'mi',
    enabled: true,
    title: 'MobilIntel',
    client: 'MobilIntel',
    location: locationsObject.dublin,
    users: 'N/A',
    industry: 'Civil Aviation',
    image: miLaptop,
    laptopImage: miLaptopCompressed,
    mobileImage: miMobile,
    logo: miLogo,
    features: [
      features.sso,
      features.userManagement,
      features.teamManagement,
      features.reports,
      features.mobile,
      features.tablet,
      features.desktop,
      features.dataMining,
    ],
    description: 'A Business Intelligence cloud application helping airlines monitor and benchmark their performance versus their competitors.',
    longDescription: 'Airlines can have a clear visualisation of how they perform against their competitors on metrics such as pricing, schedule and capacity. The app collects publicly available data from various airlines and aggregators, digests it and provides derived insights.',
  },
  {
    slug: 'ox',
    enabled: true,
    title: 'Oil Expert',
    client: 'Oil Expert',
    location: locationsObject.amsterdam,
    users: '1000+',
    industry: 'Energy',
    image: oxLaptop,
    laptopImage: oxLaptopCompressed,
    mobileImage: oxMobile,
    logo: oxLogo,
    features: [
      features.payments,
      features.sso,
      features.userManagement,
      features.documentManagement,
      features.teamManagement,
      features.reports,
      features.payWall,
      features.projects,
      features.mobile,
      features.tablet,
      features.desktop,
      features.seo,
      features.video,
    ],
    description: 'A web app and a website that helps investors in the energy sector to make more educated investment decisions.',
    longDescription: 'Investors can browse through a variety of content (artices, reports, portfolios, etc) provided by industry experts which allows them to make better investment decisions. The content is based on a freemium model and several subscriptions are provided.',
  },
  {
    slug: 'ih',
    enabled: true,
    title: 'IH App',
    client: 'International House',
    location: locationsObject.rome,
    users: '10000+',
    industry: 'Edtech',
    image: ihLaptop,
    laptopImage: ihLaptopCompressed,
    mobileImage: ihMobile,
    logo: ihLogo,
    features: [
      features.calendar,
      features.userManagement,
      features.accounting,
      features.ecommerce,
      features.reports,
      features.searchEngine,
      features.sso,
      features.darkMode,
      features.multiLanguage,
      features.pwa,
      features.seo,
      features.mobile,
      features.tablet,
      features.desktop,
    ],
    description: 'A custom build backoffice app to manage all operations of the International House Language School in Rome, Italy.',
    longDescription: 'And Edu-Tech holistic cloud application for IH Roma. One of the most established language schools in the centre of Rome and an official Cambridge Centre. The application covers a variety of features, from a calendar to manage events, lessons and class, to full accounting functionalities integrated with other parts of the app. Teachers are able to login and fill out lesson registers marking which students are present, whether they completed their homework, leave lesson summary notes and much more. The app also includes a full-fledged e-commerce solution that allows IH customers to browse all of the IH courses and exams, select their desired options (such as dates and various configurations), add them to their cart and proceed with a smooth and fast checkout. The app was developed to allow all IH employees to be able to be autonomous in managing users, classes, lessons, events, orders, payments and much more. It is available in 8 different languages, including Arabic with full support for Right to Left orientation. It also supports SSO sign in with Google and Facebook as well as accessibility features such as PWA, dark-mode, blind-mode, mobile & tablet responsiveness and much more as well as being optimized for first-place SEO performance in a competitive market. The technologies used are: React, Typescript, Material UI, NodeJS, Firebase, Django, Python, PostgreSQL, Google App Engine and Cloudflare.',
  },
  {
    slug: 'tappr',
    enabled: true,
    title: 'Tappr Network',
    client: 'Tappr Network',
    location: locationsObject.rotterdam,
    users: '100+',
    industry: 'E-Commerce',
    image: tapprLaptop,
    laptopImage: tapprLaptopCompressed,
    logo: tapperLogo,
    features: [
      features.ecommerce,
      features.sso,
      features.pwa,
      features.seo,
      features.mobile,
      features.nfc,
      features.nft,
      features.multiTenant,
      features.templatingEngine,
      features.notifications,
    ],
    description: 'A whitelabel network that connects brands to consumers via nfc and nfts.',
    longDescription: 'A whitelabel network that connects brands to consumers via nfc and nfts.',
  },
  {
    slug: 'cx',
    enabled: true,
    title: 'Ciphix',
    client: 'Ciphix',
    location: locationsObject.rotterdam,
    users: '100+',
    industry: 'AI',
    image: cxLaptop,
    laptopImage: cxLaptopCompressed,
    logo: cxLogo,
    features: [
      features.userManagement,
      features.accounting,
      features.ml,
      features.nlp,
      features.teamManagement,
      features.reports,
      features.sso,
      features.mobile,
      features.tablet,
      features.desktop,
      features.notifications,
      features.darkMode,
    ],
    description: 'An RPA & AI app that provides robotic process automation',
    longDescription: 'An RPA & AI app that provides robotic process automation',
  },
  {
    slug: 'glopal',
    enabled: true,
    title: 'Glopal Marketplace',
    client: 'Glopal',
    location: locationsObject.nice,
    users: '1.000.000+',
    industry: 'E-Commerce',
    image: glopalLaptop,
    laptopImage: glopalLaptopCompressed,
    logo: glopalLogo,
    features: [
      features.seo,
      features.ecommerce,
      features.mobile,
      features.tablet,
      features.desktop,
      features.payments,
      features.multiLanguage,
      features.multiCurrency,
      features.dataMining,
      features.nlp,
    ],
    description: 'An international e-commerce marketplace creating localized listings of retail products.',
  },
  {
    slug: 'my-limo',
    enabled: true,
    title: 'My Limo',
    client: 'My Limo',
    location: locationsObject.rome,
    users: '10.000+',
    industry: 'Travel',
    logo: myLimoLogo,
    laptopImage: myLimoLaptopCompressed,
    image: myLimoLaptop,
    features: [
      features.seo,
      features.ecommerce,
      features.mobile,
      features.tablet,
      features.desktop,
      features.payments,
      features.searchEngine,
      features.userManagement,
      features.teamManagement,
      features.calendar,
      features.accounting,
      features.maps,
    ],
    description: 'A private limo booking service. Customers can book luxury transfers and tours in Italy',
  },
  {
    slug: 'etoilepedia',
    enabled: true,
    title: 'Etoilepedia',
    client: 'Boscolo Group',
    location: locationsObject.rome,
    users: '10 000+',
    industry: 'E-Commerce',
    image: etoilepediaLaptop,
    laptopImage: etoilepediaLaptopCompressed,
    logo: etoilepediaLogo,
    features: [
      features.seo,
      features.ecommerce,
      features.mobile,
      features.tablet,
      features.desktop,
      features.payments,
      features.searchEngine,
      features.accounting,
      features.userManagement,
      features.teamManagement,
      features.calendar,
      features.video,
    ],
    description: 'An online marketplace for Michelin Chefs to buy and sell recipes.',
  },
].map((client: Client) => ({
  ...client,
  featureKeys: client.features.map(({ key }) => key),
  techStack: clientTechStack?.[client.slug as keyof typeof clientTechStack],
  techStackLabels: (clientTechStack?.[client.slug as keyof typeof clientTechStack]?.nodes || []).map(({ label }: { label: string }) => label),
})).filter(({ enabled }) => enabled);


export const portfolioClients = portfolioData.reduce((prev, next) => {
  return prev.set(next.slug, next);
}, new Map<string, typeof portfolioData[0]>());
