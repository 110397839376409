import React, { FC } from 'react';
import { styled } from '@mui/styles';

import laptopOverlay from 'shared/img/laptop.png';


const LaptopContainer = styled('div')(() => ({
  position: 'relative',
  top: 0,
  left: -60,

  transitionDuration: '200ms',
  transform: 'scale(1)',
  '&:hover': {
    transform: 'scale(1.1)',
  },
}));

const LaptopOverlay = styled('img')(({ theme }) => ({
  position: 'absolute',
  top: 10,
  left: 35,
  width: 550,
  height: 'auto',

  [theme.breakpoints.down('sm')]: {
    left: 70,
    width: 350,
  },
}));

const LaptopImage = styled('img')(({ theme }) => ({
  position: 'absolute',
  width: 420,
  height: 260,
  top: 25,
  left: 100,
  zIndex: 4,
  objectFit: 'cover',
  objectPosition: 'top center',

  [theme.breakpoints.down('sm')]: {
    width: 267,
    height: 160,
    left: 112,
    top: 22,
    transform: 'none',
  },
}));

type Props = {
  title: string;
  laptopImage: string;
}

export const PortfolioSectionLaptop: FC<Props> = ({
  title,
  laptopImage,
}) => {
  return (
    <LaptopContainer>
      <LaptopImage
        title={title}
        src={laptopImage}
      />
      <LaptopOverlay
        title={title}
        src={laptopOverlay}
      />
    </LaptopContainer>
  )
}
