import { styled } from '@mui/styles';
import { Card } from '@mui/material';


export const CardPaper = styled(Card)(({ theme }) => ({
  position: 'relative',
  overflow: 'visible',
  // height: '100%',
  width: 500,
  margin: 20,
  marginBottom: 'auto',
  display: 'inline-block',
  textAlign: 'left',
  whiteSpace: 'normal',
  backgroundColor: 'transparent !important',
  backgroundImage: 'unset !important',
  boxShadow: 'unset !important',
  [theme.breakpoints.down('sm')]: {
    width: 'calc(100vw - 20px)',
  },
}));
