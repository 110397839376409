import {
  AccountBalance,
  AccountCircle,
  AccountTree,
  Brightness7,
  CardMembership,
  Code,
  DesktopMac,
  Equalizer,
  Euro,
  Event,
  ExitToApp,
  Extension,
  MobileFriendly,
  Notifications,
  Payment,
  People,
  PhoneAndroid,
  Search,
  Store,
  TabletMac,
  TextFields,
  Translate,
  Storage,
  TapAndPlay,
  PeopleOutline,
  Brush,
  OndemandVideo,
  Map,
  Assignment,
  Description,
} from '@mui/icons-material';

export const features = {
  calendar: {
    key: 'calendar',
    Icon: Event,
  },
  accounting: {
    key: 'accounting',
    Icon: AccountBalance,
  },
  payments: {
    key: 'payments',
    Icon: Payment,
  },
  sso: {
    key: 'sso',
    Icon: ExitToApp,
  },
  ecommerce: {
    key: 'ecommerce',
    Icon: Store,
  },
  documentManagement: {
    key: 'documentManagement',
    Icon: Description,
  },
  userManagement: {
    key: 'userManagement',
    Icon: AccountCircle,
  },
  teamManagement: {
    key: 'teamManagement',
    Icon: People,
  },
  reports: {
    key: 'reports',
    Icon: Equalizer,
  },
  searchEngine: {
    key: 'searchEngine',
    Icon: Search,
  },
  payWall: {
    key: 'paywall',
    Icon: CardMembership,
  },
  notifications: {
    key: 'notifications',
    Icon: Notifications,
  },
  projects: {
    key: 'projects',
    Icon: AccountTree,
  },
  tasks: {
    key: 'tasks',
    Icon: Assignment,
  },
  mobile: {
    key: 'mobile',
    Icon: MobileFriendly,
  },
  tablet: {
    key: 'tablet',
    Icon: TabletMac,
  },
  desktop: {
    key: 'desktop',
    Icon: DesktopMac,
  },
  darkMode: {
    key: 'darkMode',
    Icon: Brightness7,
  },
  multiLanguage: {
    key: 'multiLanguage',
    Icon: Translate,
  },
  pwa: {
    key: 'pwa',
    Icon: PhoneAndroid,
  },
  seo: {
    key: 'seo',
    Icon: Search,
  },
  chromeExtension: {
    key: 'chromeExtension',
    Icon: Extension,
  },
  multiCurrency: {
    key: 'multiCurrency',
    Icon: Euro,
  },
  dataMining: {
    key: 'dataMining',
    Icon: Storage,
  },
  ml: {
    key: 'machineLearning',
    Icon: Code,
  },
  nlp: {
    key: 'nlp',
    Icon: TextFields,
  },
  nfc: {
    key: 'nfc',
    Icon: TapAndPlay,
  },
  nft: {
    key: 'nft',
    Icon: AccountBalance,
  },
  multiTenant: {
    key: 'multiTenant',
    Icon: PeopleOutline,
  },
  templatingEngine: {
    key: 'templatingEngine',
    Icon: Brush,
  },
  video: {
    key: 'video',
    Icon: OndemandVideo,
  },
  maps: {
    key: 'maps',
    Icon: Map,
  },
}
