import React, { forwardRef, useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import { Box, Chip, Container, Grid, Typography } from '@mui/material';

import { BaseSection } from 'shared/sections/BaseSection/BaseSection';
import { SectionTitle } from 'shared/sections/SectionTitle/SectionTitle';
import { features } from 'data/features.data';
import { LocalizationContext } from 'contexts/LocalizationContext/LocalizationContext';
import { MenuItemElement } from 'contexts/LayoutContext/LayoutContext.types';

export const FeaturesSection = forwardRef<MenuItemElement>((props, ref) => {
  const navigate = useNavigate();
  const { dictionary } = useContext(LocalizationContext);

  return (
    <BaseSection ref={ref}>
      <SectionTitle onEnterViewport={() => navigate('/features')}>
        Features
      </SectionTitle>
      <Typography align="center" fontSize={18}>
        Here are some features we have developed for our clients.
      </Typography>
      <Box mt={2}/>
      <Container maxWidth="lg">
        <Grid container spacing={2} justifyContent="center">
          {Object.keys(features)
            .map((key ) => ({ ...features[key as keyof typeof features] }))
            .map(({ key, Icon }, i) => (
              <Grid item key={i}>
                <Chip
                  style={{ padding: 8, height: 48, borderRadius: 48 }}
                  label={<Typography>{dictionary?.[key as keyof typeof dictionary] || key}</Typography>}
                  icon={<Icon fontSize="large"/>}
                />
              </Grid>
            ))}
        </Grid>
      </Container>
    </BaseSection>
  );
});
