import React, { Fragment, forwardRef, useContext, useRef, useState, useMemo } from 'react';
import { useParams } from 'react-router';
import { useNavigate } from 'react-router-dom';
import { Box, Button, CircularProgress, Grid, Typography } from '@mui/material';
import { Event } from '@mui/icons-material';

import { LocalizationContext } from 'contexts/LocalizationContext/LocalizationContext';
import { LayoutContext } from 'contexts/LayoutContext/LayoutContext';
import { BaseSection } from 'shared/sections/BaseSection/BaseSection';
import { SectionTitle } from 'shared/sections/SectionTitle/SectionTitle';
import logo from 'shared/img/logos/cvt.svg';
import mpLogo from 'shared/img/logos/mpLogo.webp';
import catLogo from 'shared/img/logos/cat.png';
import ikeaLogo from 'shared/img/logos/ikeaLogo.png';
import samsungLogo from 'shared/img/logos/samsungLogo.svg';
import { MenuItemElement } from 'contexts/LayoutContext/LayoutContext.types';


export const HomeSection = forwardRef<MenuItemElement>((props, ref) => {
  const navigate = useNavigate();
  const { sectionId = 'home' } = useParams<{ sectionId: string }>();
  const firstSection = useRef(sectionId);
  const isMentourLandingPage = useMemo(() => {
    return firstSection.current === 'mentour';
  }, [])

  const {dictionary} = useContext(LocalizationContext);
  const { isMobile, useDarkTheme } = useContext(LayoutContext);
  const [loading, setLoading] = useState(false);

  return (
    <BaseSection ref={ref} style={{ padding: 12 }}>
      <img
        alt="CVT Logo"
        src={logo}
        style={{ height: 150, width: 'auto', marginTop: isMobile ? 200 : (isMentourLandingPage ? 220 : 280), marginLeft: 12, marginBottom: 16 }}
      />
      <SectionTitle color="primary" onEnterViewport={() => sectionId !== 'mentour' && navigate('/home')}>
        Creating Value with Tech
      </SectionTitle>
      <br/>
      <Typography variant="h5">
        We help early-stage B2B SaaS Founders go from 0 to 1 million $ ARR{!isMobile ? <br/> : ''} by creating scalable & <u>valuable</u> Products.
      </Typography>
      <Typography variant="h6" color="primary" pt={1}>
        <i>{'With less than 100k $ initial investment'}</i>
      </Typography>
      <br/>
      <Button
        color="secondary"
        variant="contained"
        size="large"
        style={{textTransform: 'none'}}
        startIcon={loading ? <CircularProgress style={{ width: 24, height: 24 }}/> : <Event/>}
        onClick={() => {
          //@ts-ignore
          window.lintrk('track', { conversion_id: 11423801 });
          setLoading(true);
          setTimeout(() => {
            window.open('https://meetings.hubspot.com/my11', '_blank');
            setLoading(false);
          }, 2000);
        }}
        disabled={loading}
      >
        <Typography variant="h6">
          {dictionary.homeSectionCTAText}
        </Typography>
      </Button>
      <Box mt={10}>
        <Typography variant="body1" style={{ opacity: 0.5 }}><i>{isMentourLandingPage ? 'As featured on' : 'Trusted By'}:</i></Typography>
        <Grid container justifyContent="center" alignItems="center" spacing={isMobile ? 2 : 6} direction={isMobile ? 'column' : 'row'}>
          {isMentourLandingPage ? (
            <Grid item>
              <img
                alt="Mentour"
                src={mpLogo}
                style={{
                  height: 44,
                  width: 214,
                  filter: 'grayscale(1) brightness(0%)',
                  opacity: 1,
                  ...isMobile && {
                    marginBottom: 24,
                    marginTop: 40,
                  },
                  ...useDarkTheme && {
                    filter: 'grayscale(1) brightness(10)',
                  }
                }}
              />
            </Grid>
          ) : (
            <Fragment>
              <Grid item>
                <img
                  alt="IKEA"
                  src={ikeaLogo}
                  style={{
                    height: 30,
                    width: 150,
                    filter: 'grayscale(1) brightness(0%)',
                    opacity: 0.7,
                    ...useDarkTheme && {
                      filter: 'grayscale(1) brightness(10)',
                    }
                  }}
                />
              </Grid>
              <Grid item>
                <img
                  alt="Caterpillar"
                  src={catLogo}
                  style={{
                    height: 40,
                    width: 214,
                    filter: 'grayscale(1) brightness(0%)',
                    opacity: 0.7,
                    ...isMobile && {
                      marginBottom: 24,
                      marginTop: 40,
                    },
                    ...useDarkTheme && {
                      filter: 'grayscale(1) brightness(10)',
                    }
                  }}
                />
              </Grid>
              <Grid item>
                <img
                  alt="Samsung"
                  src={samsungLogo}
                  style={{
                    height: 100,
                    width: 178,
                    filter: 'grayscale(1) brightness(0%)',
                    opacity: 0.7,
                    ...useDarkTheme && {
                      filter: 'grayscale(1) brightness(10)',
                    }
                  }}
                />
              </Grid>
            </Fragment>
          )}

        </Grid>
      </Box>
    </BaseSection>
  );
});
