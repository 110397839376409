export const whySectionData = [
  [
    'On your Own',
    'Together',
  ],
  [
    'Take the sole responsibility for building the right thing',
    'With our experience we can define what to build and when',
  ],
  [
    'Juggle between building and selling',
    'You focus on selling we will take care of building',
  ],
  [
    'Go through the trial and error of hiring the right people',
    'We\'re well beyond the trial and error stage',
  ],
  [
    'Spend resources building a strong, cross-functional, team',
    'Backend, Frontend, Data Science, UX/UI, QA, etc are covered',
  ],
  [
    'Invest significant capital right away',
    'Start with as little as 5/10k a month',
  ],
  [
    'Be locked in payroll and skillset with little flexibility',
    'Alter capacity, budget or skills within weeks notice',
  ],
  [
    'Wait months before your hires start working',
    'Start working weeks from now',
  ],
  [
    'Wait months before they can deliver value',
    'Ready to deliver value right away',
  ],
];