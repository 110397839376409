import React, { useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import { Button, Card, CardActions, CardHeader, Container, Stack, Typography } from '@mui/material';
import { ChevronRight } from '@mui/icons-material';

import { routes } from 'config';
import { LocalizationContext } from 'contexts/LocalizationContext/LocalizationContext';
import { LayoutContext } from 'contexts/LayoutContext/LayoutContext';
import logo from 'shared/img/logos/cvt.svg';


export const Labs = () => {

  const navigate = useNavigate();
  const { isMobile } = useContext(LayoutContext)
  const { dictionary } = useContext(LocalizationContext)

  return (
    <Container maxWidth={false} sx={{ textAlign: 'center' }}>
      <img
        alt="CVT Logo"
        src={logo}
        style={{ height: 150, width: 'auto', marginTop: isMobile ? 233 : 353, marginLeft: 12, marginBottom: 16 }}
      />
      <Typography align="center" sx={{ mt: 6 }} variant="h3">{dictionary.cvtLabs}</Typography>
      <Typography align="center" sx={{ mt: 1 }} variant="h6">{dictionary.cvtLabsDescription}</Typography>
      <Stack direction="row" justifyContent="center" spacing={2} sx={{ mt: 2 }}>
        <Card sx={{ maxWidth: 400 }}>
          <CardHeader
            sx={{ textAlign: 'left' }}
            title={<Typography color="primary" variant="h5">CVT AI API</Typography>}
            subheader="A general purpose AI driven API that improves as you use it. Just make calls to it and let it improve and learn."
          />
          <CardActions>
            <Button
              variant="outlined"
              sx={{ ml: 'auto' }}
              endIcon={<ChevronRight/>}
              onClick={() => navigate(routes.labsProject('ai-api'))}
            >
              Learn More
            </Button>
          </CardActions>
        </Card>
        <Card sx={{ maxWidth: 400 }}>
          <CardHeader
            sx={{ textAlign: 'left' }}
            title={<Typography color="primary" variant="h5">CVT Cookie Cutter</Typography>}
            subheader="Our own CLI tool to spin up our techstack with frontend and backend skeletons ready to go with CI, CD, Docker and much more."
          />
          <CardActions>
            <Button
              disabled
              variant="outlined"
              sx={{ ml: 'auto' }}
              endIcon={<ChevronRight/>}
            >
              Coming Soon
            </Button>
          </CardActions>
        </Card>
      </Stack>
    </Container>
  )
}
