import { createTheme as createMuiTheme, responsiveFontSizes } from '@mui/material';


export const pastelPalette = [
  '#ffb3ba',
  '#ffdfba',
  '#ffffba',
  '#baffc9',
  '#bae1ff',
  '#c7baff',
];

export const palette = {
  lightGrey: '#A5A5A5',
  eerieBlack: '#070708',
  fluorescentBlue: '#15FFF8',
  white: '#FFFFFF',
  primary: '#6365FF',
  secondary: '#8000D4',
};

const darkGradientBackground = 'radial-gradient(circle, rgba(29,29,31,1) 0%, rgba(0,0,0,1) 100%)';
const lightGradientBackground = 'radial-gradient(circle, rgba(114,117,233,0.3) 0%, rgba(114,117,233,0.10) 100%)';

export const novaFont = 'proxima-nova';
export const avantGardeFont = 'itc-avant-garde-gothic-pro';

export const createTheme = (isDarkMode: boolean) => responsiveFontSizes(createMuiTheme({
  typography: {
    fontFamily: `${avantGardeFont}, sans-serif`,
    h1: {
      // fontSize: 40,
      fontWeight: 700,
      lineHeight: 1.5,
      fontFamily: `${avantGardeFont}, sans-serif`,
    },
    // h3: {
    //   fontSize: 44,
    //   fontWeight: 400,
    // },
    fontSize: 13,
  },
  palette: {
    mode: isDarkMode ? 'dark' : 'light',
    common: {
      eerieBlack: palette.eerieBlack,
      lightGrey: palette.lightGrey,
      fluorescentBlue: palette.fluorescentBlue,
      white: palette.white,
      cornflowerBlue: palette.primary,
      electricViolet: palette.secondary,
    },
    primary: {
      main: palette.primary,
    },
    secondary: {
      main: palette.secondary,
    },
    background: {
      ...isDarkMode && {paper: '#343434'},
    },
    text: {
      secondary: palette.lightGrey,
    },
  },

  components: {
    MuiCssBaseline: {
      styleOverrides: {
        body: {
          fontSize: '0.875rem',
          lineHeight: 1.43,
          letterSpacing: '0.01071em',

          '&:after': {
            content: '""',
            position: 'fixed',
            top: 0,
            left: 0,
            height: '100vh',
            width: '100vw',
            background: isDarkMode ? darkGradientBackground : lightGradientBackground,
          },
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        root: {
          color: '#fff',
        },
      }
    },
    MuiCardContent: {
      styleOverrides: {
        root: {
          padding: 30,
        },
      }
    },
    MuiCardHeader: {
      styleOverrides: {
        root: {
          padding: 30,
          paddingBottom: 0,
        },
      }
    },
    MuiCardActions: {
      styleOverrides: {
        root: {
          paddingTop: 0,
          padding: 30,
          justifyContent: 'center',
          width: '100%',
        },
      },
    },
    MuiIconButton: {
      styleOverrides: {
        root: {
          padding: 12,
        },
      }
    },
    MuiSwitch: {
      styleOverrides: {
        colorPrimary: {
          color: '#BF2B35',
          '& + .MuiSwitch-track': {
            backgroundColor: '#BF2B35',
          },
          '&.Mui-checked': {
            color: '#96C21E',
          },
          '&.Mui-checked + .MuiSwitch-track': {
            backgroundColor: '#96C21E',
          },
        },
      }
    },
    MuiChip: {
      styleOverrides: {
        icon: {
          marginLeft: 8,
        },
      }
    },
    MuiTooltip: {
      styleOverrides: {
        tooltip: {
          fontSize: 12,
        },
      }
    },
  },
}));
