import { styled } from '@mui/styles';
import { Box, Paper } from '@mui/material';
import '@justinribeiro/lite-youtube';

// @ts-ignore
export const SuccessStoriesVideo = styled('lite-youtube')(() => ({}));

export const AvatarImage = styled('img')(({ theme }) => ({
  border: `1px solid ${theme.palette.primary.main}`,
  borderRadius: '50%',
  width: 38,
  height: 38,
}));

export const VideoPlaceholder = styled(Box)(() => ({
  width: '100%',
  height: '100%',
  backgroundPosition: 'center',
  backgroundSize: '100% 100%',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
}));

export const SuccessStoriesVideoContainer = styled(Paper)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  width: 'calc(100vw - 24px)',
  background: theme.palette.mode === 'dark' ? '#1F1F21' : undefined,
  maxWidth: 355,
  minWidth: 300,
  height: 240,

  [theme.breakpoints.up(375)]: {
    height: 260,
  },

  [theme.breakpoints.up('sm')]: {
    maxWidth: 'unset',
    minWidth: 'unset',
    width: 475,
    height: 340,
  },

  [theme.breakpoints.up('md')]: {
    width: 375,
    height: 280,
  },

  [theme.breakpoints.up('lg')]: {
    width: 580,
    height: 390,
  },
}));
