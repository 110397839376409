export const dictionary = {
  home: 'Home',
  whyUs: 'Why Us',
  testimonials: 'Testimonials',
  portfolio: 'Portfolio',
  techstack: 'Tech Stack',
  successStories: 'Success Stories',
  team: 'Team',
  contactUs: 'Contact Us',
  solveYourProblemsThrough: 'Solve your problems through',
  technology: 'Technology',
  machineLearning: 'Machine Learning',
  aCustomBuiltApp: 'a Custom Built App',
  automatedReports: 'Automated Reports',
  homeSectionSubtitle: 'We deliver digital solutions to solve any real life problem.',
  homeSectionCTAText: 'Book a Call',
  trustedBySectionTitle: 'Trusted By',
  trustedBySectionSubtitle: 'Fortune 1000 companies and many more',
  testimonialsSectionTitle: 'Don\'t just take our word on it:',
  testimonialsSectionSubtitle: 'This is what others have to say about us:',
  portfolioSectionTitle: 'Our Portfolio',
  portfolioSectionSubtitle: 'Here are some of the problems we solved',
  techStackSectionTitle: 'Our Tech-Stack',
  techStackSectionSubtitle: 'Click on any node to interact with it.',
  teamSectionTitle: 'Our Team',
  teamSectionSubtitle: 'Our Team',
  switchToDarkMode: 'Switch to Dark Mode',
  switchToLightMode: 'Switch to Light Mode',
  changeLanguage: 'Change Language',
  playAnimations: 'Play Animations',
  pauseAnimations: 'Pause Animations',
  location: 'Location',
  users: 'Users',
  company: 'Company',
  industry: 'Industry',
  giveUsACall: 'Give us a Call',

  italy: 'Italy',
  france: 'France',
  netherlands: 'Netherlands',
  poland: 'Poland',
  switzerland: 'Switzerland',
  usa: 'USA',
  ireland: 'Ireland',
  spain: 'Spain',

  rome: 'Rome',
  nice: 'Nice',
  zurich: 'Zurich',
  warsaw: 'Warsaw',
  gdansk: 'Gdańsk',
  krakow: 'Kraków',
  lisbon: 'Lisbon',
  dubai: 'Dubai',
  novosibirsk: 'Novosibirsk',
  utrecht: 'Utrecht',
  rotterdam: 'Rotterdam',
  amsterdam: 'Amsterdam',
  austin: 'Austin',
  dublin: 'Dublin',
  barcelona: 'Barcelona',
  chicago: 'Chicago',

  feature: 'Feature',
  city: 'City',
  country: 'Country',

  calendar: 'Calendar',
  accounting: 'Accounting',
  payments: 'Payments',
  sso: 'SSO (Single Sign On)',
  ecommerce: 'E-Commerce',
  userManagement: 'User Management',
  teamManagement: 'Team Management',
  reports: 'Reports',
  searchEngine: 'Search Engine',
  paywall: 'Pay Wall',
  notifications: 'Notifications',
  projects: 'Projects',
  mobile: 'Mobile',
  tablet: 'Tablet',
  desktop: 'Desktop',
  darkMode: 'Dark Mode',
  multiLanguage: 'Multi Language',
  pwa: 'PWA (Progressive Web App)',
  seo: 'SEO (Search Engine Optimization)',
  chromeExtension: 'Chrome Extension',
  multiCurrency: 'Multi Currency',
  dataMining: 'Data Mining',
  nlp: 'NLP (Natural Language Processing)',
  nfc: 'NFC (Near Field Communication)',
  nft: 'NFT (Non Fungible Token)',
  multiTenant: 'Multi Tenant',
  templatingEngine: 'Templating Engine',
  filterByLocationFeatureOrTechnology: 'Filter by Location, Feature or Technology',
  video: 'Video',
  maps: 'Maps',
  tasks: 'Tasks',
  documentManagement: 'Document Management',
  careersSectionTitle: 'Careers',
  labs: 'Labs',
  cvtLabs: 'CVT Labs',
  cvtLabsDescription: "'Discover and experiment with valuable tools and experiments we've created at CVT",
  careersSectionDescription: 'Our customers love what we do so much that they keep referring us to their friends and colleagues. We are looking for more people to join us in our adventure of solving real-life problems through technology. We are a hybrid company where people are allowed to work from where they prefer but generally love working together from our offices.',
};

