export const openPositions = [
  {
    title: 'Software Engineer - Fullstack',
    description: 'Our team would love to welcome a new Fullstack engineer to join us in creating beautiful, scalable and consistent applications. We strive to use the latest state-of-the-art technology so we are looking for someone that will be happy to work with Python 3.9, Django REST API + ORM, Typescript 4, React, CI, CD, TDD and Microservices running on GCP.',
    location: 'Warsaw, PL (Hybrid Remote)',
    experience: 'Min. 2 yrs. of Experience',
    type: 'Full Time',
    salary: '10.000 - 20.000 PLN / Mo. (B2B or UOP)',
  },
  {
    title: 'Software Engineer - Backend',
    description: 'We are looking for a Backend Developer who wants to work on scalable and consistent applications. We strive to use the latest state-of-the-art technology so we are looking for someone that will be happy to work with Python 3.9, Django REST API + ORM, CI, CD, TDD and Microservices running on GCP.',
    location: 'Warsaw, PL (Hybrid Remote)',
    experience: 'Min. 2 yrs. of Experience',
    type: 'Full Time',
    salary: '11.000 - 15.000 PLN / Mo. (B2B or UOP)',
  },
  {
    title: 'Software Engineer - Frontend',
    description: 'Our team would love to welcome a new Frontend engineer to join us in creating beautiful, scalable and consistent applications. We strive to use the latest state-of-the-art technology so we are looking for someone that will be happy to work with Typescript 4, React, CI, CD, TDD and Microservices running on GCP.',
    location: 'Warsaw, PL (Hybrid Remote)',
    experience: 'Min. 2 yrs. of Experience',
    type: 'Full Time',
    salary: '13.000 - 18.000 PLN / Mo. (B2B or UOP)',
  },
  {
    title: 'Product Manager',
    description: 'We are looking for an experienced Product Owner ready to take ownership on the success of 2/3 products from various clients. To be the voice of customers; To understand what they need vs what they want and translate it into a prioritized and well-documented JIRA backlog',
    location: 'Warsaw, PL',
    experience: 'Min. 2 yrs. of Experience',
    type: 'Full Time',
    salary: '10.000 - 15.000 PLN / Mo. (B2B or UOP)',
  },
]
