import React, { Fragment, useContext, useEffect, memo } from 'react';
import { Grid } from '@mui/material';
import { useParams } from 'react-router';

import { LayoutContext } from 'contexts/LayoutContext/LayoutContext';
import { HomeSection } from 'shared/sections/HomeSection/HomeSection';
// import { TestimonialsSection } from 'shared/sections/TestimonialsSection/TestimonialsSection';
import { ContactUsSection } from 'shared/sections/ContactUsSection/ContactUsSection';
import { PortfolioSection } from 'shared/sections/PortfolioSection/PortfolioSection';
import { TechStackSection } from 'shared/sections/TechStackSection/TechStackSection';
import { TeamSection } from 'shared/sections/TeamSection/TeamSection';
import { ProgressBar } from 'shared/components/ProgressBar/ProgressBar';
import { FeaturesSection } from 'shared/sections/FeaturesSection/FeaturesSection';
import { CareersSection } from 'shared/sections/CareersSection/CareersSection';
import { WhySection } from 'shared/sections/WhySection/WhySection';
import { MissionVisionValuesSection } from 'shared/sections/MissionVisionValuesSection/MissionVisionValuesSection';
import { SuccessStoriesSection } from 'shared/sections/SuccessStoriesSection/SuccessStoriesSection';


type UrlParams = {
  sectionId: string;
}

export const Home = memo(() => {
  const { sectionId = 'home' } = useParams<UrlParams>();

  const {
    homeSectionRef,
    contactUsSectionRef,
    // testimonialsSectionRef,
    portfolioSectionRef,
    techStackSectionRef,
    successStoriesSectionRef,
    teamSectionRef,
    autoScrollToSection,
    careersSectionRef,
    whySectionRef,
  } = useContext(LayoutContext);

  useEffect(() => {
    autoScrollToSection(sectionId);
    // eslint-disable-next-line
  }, []);

  return (
    <Fragment>
      <ProgressBar/>
      <Grid container direction="column">
        <HomeSection ref={homeSectionRef}/>
        <WhySection ref={whySectionRef}/>
        <SuccessStoriesSection ref={successStoriesSectionRef}/>
        <PortfolioSection ref={portfolioSectionRef}/>
        <FeaturesSection/>
        <TechStackSection ref={techStackSectionRef}/>
        <MissionVisionValuesSection/>
        <TeamSection ref={teamSectionRef}/>
        <CareersSection ref={careersSectionRef}/>
        <ContactUsSection ref={contactUsSectionRef}/>
      </Grid>
    </Fragment>
  )
})
