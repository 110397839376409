export const dark = [
  {
    "featureType": "water",
    "elementType": "geometry.fill",
    "stylers": [
      {
        "color": "#474747",
      },
    ],
  },
  {
    "featureType": "transit",
    "stylers": [
      {
        "color": "#808080",
      },
      {
        "visibility": "off",
      },
    ],
  },
  {
    "featureType": "road.highway",
    "elementType": "geometry.stroke",
    "stylers": [
      {
        "visibility": "on",
      },
      {
        "color": "#393939",
      },
    ],
  },
  {
    "featureType": "road.highway",
    "elementType": "geometry.fill",
    "stylers": [
      {
        "color": "#767676",
      },
    ],
  },
  {
    "featureType": "road.local",
    "elementType": "geometry.fill",
    "stylers": [
      {
        "visibility": "on",
      },
      {
        "color": "#5a5a5a",
      },
      {
        "weight": 1.8,
      },
    ],
  },
  {
    "featureType": "road.local",
    "elementType": "geometry.stroke",
    "stylers": [
      {
        "color": "#323232",
      },
    ],
  },
  {
    "featureType": "poi",
    "elementType": "geometry.fill",
    "stylers": [
      {
        "visibility": "on",
      },
      {
        "color": "#3b3b3b",
      },
    ],
  },
  {
    "featureType": "administrative",
    "elementType": "geometry",
    "stylers": [
      {
        "color": "#5e5e5e",
      },
    ],
  },
  {
    "featureType": "road.arterial",
    "elementType": "geometry.fill",
    "stylers": [
      {
        "color": "#575757",
      },
    ],
  },
  {
    "featureType": "road.arterial",
    "elementType": "geometry.fill",
    "stylers": [
      {
        "color": "#797979",
      },
    ],
  },
  {
    "featureType": "landscape",
    "elementType": "geometry.fill",
    "stylers": [
      {
        "visibility": "on",
      },
      {
        "color": "#5f5f5f",
      },
    ],
  },
  {
    "featureType": "road",
    "elementType": "labels.text.fill",
    "stylers": [
      {
        "visibility": "off",
      },
      {
        "color": "#262626",
      },
    ],
  },
  {
    "featureType": "all",
    "elementType": "labels.text.fill",
    "stylers": [
      {
        "visibility": "off",
      },
      {
        "color": "#d0d0d0",
      },
    ],
  },
  {
    "featureType": "administrative",
    "elementType": "labels.text.stroke",
    "stylers": [
      {
        "visibility": "off",
      },
      {
        "color": "#494949",
      },
    ],
  },
  {
    "featureType": "poi",
    "elementType": "labels.icon",
    "stylers": [
      {
        "visibility": "off",
      },
    ],
  },
  {
    "featureType": "poi",
    "elementType": "labels",
    "stylers": [
      {
        "visibility": "off",
      },
    ],
  },
  {
    "featureType": "road.arterial",
    "elementType": "geometry.stroke",
    "stylers": [
      {
        "color": "#4b4b4b",
      },
    ],
  },
  {
    "featureType": "road",
    "elementType": "labels.icon",
    "stylers": [
      {
        "visibility": "off",
      },
    ],
  },
  {},
  {
    "featureType": "poi",
    "elementType": "geometry.fill",
    "stylers": [
      {
        "color": "#525252",
      },
    ],
  },
];
