import React, { FC } from 'react';
import { Box, styled, Typography } from '@mui/material';

import twoSideArrow from 'shared/img/arrows.svg';


const ItemContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  width: '80%',
  margin: theme.spacing(5, 0),
}));

const StyledImage = styled('img')(() => ({
  position: 'absolute',
  transform: 'translate(-50%, -50%)'
}))

type Props = {
  pros: string;
  cons: string;
}

export const WhySectionComparisonHeader: FC<Props> = ({
  pros,
  cons,
}) => {
  return (
    <ItemContainer>
      <Box flex={1}>
        <Typography
          color="textSecondary"
          fontSize={24}
          fontWeight={500}
          align="right"
        >
          {cons}
        </Typography>
      </Box>
      <Box position="relative" width={130}>
        <StyledImage
          src={twoSideArrow}
          alt="twoSideArrow"
        />
      </Box>
      <Box flex={1}>
        <Typography
          color="textSecondary"
          fontSize={24}
          fontWeight={500}
          align="left"
        >
          {pros}
        </Typography>
      </Box>
    </ItemContainer>
  );
}
