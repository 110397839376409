import React, { FC, useContext, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Flag from 'react-country-flag';
import { makeStyles } from '@mui/styles';
import { Business, ChevronRight, Group, Work } from '@mui/icons-material';
import {
  Box,
  Button,
  CardActions,
  CardContent,
  CardHeader,
  Grid,
  Tooltip,
  Typography
} from '@mui/material';

import { LocalizationContext } from 'contexts/LocalizationContext/LocalizationContext';
import { LayoutContext } from 'contexts/LayoutContext/LayoutContext';
import { Client } from 'data/portfolio.data';
import { routes } from 'config';
import { PortfolioSectionClippedBackground } from 'shared/sections/PortfolioSection/PortfolioSectionClippedBackground';
import { CardPaper } from 'shared/sections/PortfolioSection/CardPaper';
import { PortfolioSectionLaptop } from 'shared/sections/PortfolioSection/PortfolioSectionLaptop';
import { PortfolioSectionMobile } from 'shared/sections/PortfolioSection/PortfolioSectionMobile';

const useStyles = makeStyles((theme) => {
  return {
    carouselItem: {
      position: 'relative',
      overflow: 'visible',
      width: 500,
    margin: 20,
    marginBottom: 'auto',
    display: 'inline-block',
    textAlign: 'left',
    whiteSpace: 'normal',
    background: 'none',
    boxShadow: 'none',
    [theme.breakpoints.down('sm')]: {
      width: 'calc(100vw - 20px)',
    },
  },
  clippedBackground: {
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    backgroundColor: theme.palette.mode === 'dark' ? '#ececec' : '#ececec',
    clipPath: 'polygon(0 41%, 100% 15%, 100% 100%, 0% 100%)',
    zIndex: -1,
  },
  carouselItemLogo: {
    height: 50,
    width: 'auto',
    maxWidth: 250,
    marginTop: 320,
    // filter: `drop-shadow(0px 0px 6px ${theme.palette.type === 'dark' ? '#ececec50' : '#99999950'})`,
    [theme.breakpoints.down('sm')]: {
      height: 'auto',
      width: '100%',
      maxWidth: 150,
      marginTop: 220,
    },
  },
  infoGrid: {
    marginBottom: theme.spacing(1),
    color: '#444',},
  };
});

type Props = Client;
export const PortfolioSectionItem: FC<Props> = ({
  title,
  slug,
  client,
  logo,
  location,
  users,
  industry,
  laptopImage,
  mobileImage,
  description,
}) => {
  const [isHovered, setIsHovered] = useState(false);
  const navigate = useNavigate();
  const {dictionary} = useContext(LocalizationContext);
  const {isMobile} = useContext(LayoutContext);
  const classes = useStyles({isHovered, isMobile});

  return (
    <Grid
      item
      sx={{
        '&:first-of-type': {
          marginLeft: isMobile ? -1.875 : 2.5,
        },
      }}
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
    >
      <CardPaper
        sx={{
          overflow: 'unset',
        }}
      >
        <PortfolioSectionClippedBackground/>
        <PortfolioSectionLaptop
          title={title}
          laptopImage={laptopImage}
        />
        {mobileImage && (
          <PortfolioSectionMobile
            title={title}
            mobileImage={mobileImage}
          />
        )}
        <CardHeader
          title={logo ? <img className={classes.carouselItemLogo} alt={title} src={logo}/> : title}
        />
        <CardContent>
          <Grid container alignItems="center" justifyContent="space-between">
            <Grid item>
              <Grid container alignItems="center" className={classes.infoGrid}>
                <Tooltip title={dictionary.location}>
                  <>
                    <Flag countryCode={location.countryCode} style={{width: 24, height: 24, borderRadius: '100%'}} svg/>
                  </>
                </Tooltip>
                &nbsp;&nbsp;{dictionary[location.city as keyof typeof dictionary]}, {dictionary[location.country as keyof typeof dictionary]}
              </Grid>
            </Grid>
            <Grid item>
              <Grid container alignItems="center" className={classes.infoGrid}>
                {users}&nbsp;&nbsp;
                <Tooltip title={dictionary.users}>
                  <>
                    <Group/>
                  </>
                </Tooltip>
              </Grid>
            </Grid>
          </Grid>
          <Grid container alignItems="center" justifyContent="space-between">
            <Grid item>
              <Grid container alignItems="center" className={classes.infoGrid}>
                <Tooltip title={dictionary.company}>
                  <>
                    <Work/>
                  </>
                </Tooltip>
                &nbsp;&nbsp;{client}
              </Grid>
            </Grid>
            <Grid item>
              <Grid container alignItems="center" className={classes.infoGrid}>
                {industry}&nbsp;&nbsp;
                <Tooltip title={dictionary.industry}>
                  <>
                    <Business/>
                  </>
                </Tooltip>
              </Grid>
            </Grid>
          </Grid>
          <Typography style={{minHeight: 70, marginTop: 8, color: '#444'}}>
            {description}
          </Typography>
        </CardContent>
        <CardActions style={{marginTop: 'auto'}}>
          <Box ml="auto"/>
          <Button
            color="secondary"
            variant="contained"
            endIcon={<ChevronRight/>}
            onClick={() => navigate(routes.portfolioClient(slug))}
          >
            Open
          </Button>
        </CardActions>
      </CardPaper>
    </Grid>
  );
};
