import React, { FC, Fragment, useContext, useMemo, useState } from 'react';
import Flag from 'react-country-flag'
import { Box, Chip, Container, Grid, IconButton, Paper, Tooltip, Typography } from '@mui/material';
import { Business, Close, Group, Work } from '@mui/icons-material';
import { useParams } from 'react-router';
import { useNavigate } from 'react-router-dom';

import { portfolioClients } from 'data/portfolio.data';
import { GraphChart } from 'shared/components/GraphChart/GraphChart';
import { useScrollTop } from 'shared/hooks/useScrollTop';
import { LocalizationContext } from 'contexts/LocalizationContext/LocalizationContext';
import { LayoutContext } from 'contexts/LayoutContext/LayoutContext';

import { PortfolioSection } from '../../sections/PortfolioSection/PortfolioSection';


type UrlParams = {
  slug?: string
};

export const PortfolioClient: FC = () => {
  useScrollTop();
  const [highResImageLoaded, setHighResImageLoaded] = useState(false);
  const navigate = useNavigate();
  const { slug = '' } = useParams<UrlParams>();
  const { dictionary } = useContext(LocalizationContext);
  const { isMobile } = useContext(LayoutContext);
  const client = useMemo(() => slug && portfolioClients.get(slug), [slug])

  if (!client) {
    return null;
  }

  const { location: { city, country, countryCode }, logo } = client;

  return (
    <>
      <Container maxWidth="lg">
        <Box p={4} m={isMobile ? 0 : 6} mt={12} component={Paper}>
          <Grid container spacing={2} alignItems="center">
            <Grid
              item
              xs={12}
              sx={{
                textAlign: isMobile ? 'center' : 'left',
                marginBottom: 2,
                position: 'relative',
              }}
            >
              <img
                src={logo}
                alt={client.title}
                style={{
                  height: 50,
                  width: 'auto',
                  maxWidth: isMobile ? 200 : 300,
                }}/>
              <IconButton
                sx={{
                  position: 'absolute',
                  right: 0,
                  top: 0,
                }}
                onClick={() => navigate('/portfolio')}
              >
                <Close/>
              </IconButton>
            </Grid>
            <Grid item xs={12}>
              <Grid container spacing={2}>
                <Grid item>
                  <Tooltip title={dictionary.company}>
                    <Chip label={client.client} icon={<Work/>}/>
                  </Tooltip>
                </Grid>
                <Grid item>
                  <Tooltip title={dictionary.location}>
                    <Chip
                      label={`${dictionary[city as keyof typeof dictionary]}, ${dictionary[country as keyof typeof dictionary]}`}
                      icon={<Flag countryCode={countryCode} style={{ width: 24, height: 24, borderRadius: '100%' }} svg/>}
                    />
                  </Tooltip>
                </Grid>
                <Grid item>
                  <Tooltip title={dictionary.users}>
                    <Chip label={client.users} icon={<Group/>}/>
                  </Tooltip>
                </Grid>
                <Grid item>
                  <Tooltip title={dictionary.industry}>
                    <Chip label={client.industry} icon={<Business/>}/>
                  </Tooltip>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12}>
              <Typography>{client.description}</Typography>
            </Grid>
            <Grid item xs={12}>
              <Box width="100%" height="100%" position="relative">
                <img
                  src={client.laptopImage}
                  alt={client.title}
                  style={{
                    width: '100%',
                    height: 'auto',
                  }}
                />
                <img
                  src={client.image}
                  alt={client.title}
                  style={{
                    position: 'absolute',
                    width: '100%',
                    height: 'auto',
                    top: 0,
                    left: 0,
                    opacity: highResImageLoaded ? 1 : 0,
                  }}
                  onLoad={() => setHighResImageLoaded(true)}
                />
              </Box>
            </Grid>
            <Grid item xs={12}>
              <Box mt={1}/>
              <Typography variant="h4" color="primary">Features:</Typography>
              <Box mt={1}/>
              <Grid container spacing={2}>
                {(client?.features || []).map(({ key, Icon }, i) => (
                  <Grid item key={i}>
                    <Chip label={dictionary?.[key as keyof typeof dictionary] || key} icon={<Icon/>}/>
                  </Grid>
                ))}
              </Grid>
              <Box mb={4}/>
            </Grid>
            <Grid item xs={12}>
              <Typography variant="h4" color="primary">Description:</Typography>
              <Box mt={1}/>
              <Typography>{client.longDescription}</Typography>
              <Box mb={1}/>
            </Grid>
          </Grid>
        </Box>
        {client?.techStack && (
          <Fragment>
            <Box mt={4}/>
            <Typography variant="h4" align="center" color="primary">Tech-Stack Used for {client.title}</Typography>
            <div style={{ width: '100%', height: 1000, marginBottom: 200 }}>
              <GraphChart key={slug} data={client.techStack}/>
            </div>
          </Fragment>
        )}
      </Container>

      <PortfolioSection autoUrlChange={false}/>
    </>
  );
}
