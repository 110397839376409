import React, { FC } from 'react';
import { Grid, Typography, Link, Box } from '@mui/material';
import { LinkedIn } from '@mui/icons-material';

import {
  SuccessStoriesVideoContainer,
  SuccessStoriesVideo,
  VideoPlaceholder, AvatarImage
} from 'shared/sections/SuccessStoriesSection/StyledComponents';
import { VideoObject } from 'data/successStories.data';


type Props = {
  customer: string;
  jobTitle: string;
  company: string;
  linkedIn: string;
  avatar?: string;
  video?: VideoObject;
  videoPlaceholder?: string;
}

export const SuccessStoryItem: FC<Props> = ({
  video,
  customer,
  jobTitle,
  company,
  linkedIn,
  avatar,
  videoPlaceholder,
}) => {
  return (
    <Grid
      item
      sx={{
        mr: 3,
        '&:last-of-type': {
          mr: 0,
        },
      }}
    >
      <SuccessStoriesVideoContainer>
        {video && (
          <SuccessStoriesVideo
            videotitle={video.title}
            videoid={video.url}
          />
        )}
        {!video && (
          <VideoPlaceholder sx={{
            backgroundImage: `url(${videoPlaceholder})`,
          }}>
            <Typography color="primary" fontWeight="bold">Video coming soon</Typography>
          </VideoPlaceholder>
        )}
        <Box display="flex" px={1}>
          <Box
            display="flex"
            alignItems="center"
            p={1}
          >
            <AvatarImage
              alt={customer}
              src={avatar}
            />
          </Box>
          <Box
            flex={1}
            display="flex"
            flexDirection="column"
            p={1}
          >
            <Typography textAlign="start">{customer}</Typography>
            <Typography textAlign="start" color="primary">{jobTitle} of <b>{company}</b></Typography>
          </Box>
          <Box
            display="flex"
            alignItems="center"
            p={1}
          >
            <Link
              href={linkedIn}
              target="_blank"
              sx={{
                width: 40,
                height: 40,
                color: (theme) => `${theme.palette.primary.main} !important`,
              }}
            >
              <LinkedIn
                sx={{
                  width: '100%',
                  height: '100%',
                }}
              />
            </Link>
          </Box>
        </Box>
      </SuccessStoriesVideoContainer>
    </Grid>
  );
}
