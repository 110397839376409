import React, { FC, useContext } from 'react';
import { LocationOn } from '@mui/icons-material';
import { makeStyles } from '@mui/styles';
import { Tooltip } from '@mui/material';

import { LocalizationContext } from 'contexts/LocalizationContext/LocalizationContext';


type Props = {
  lat: number; // needed for google-map-react library
  lng: number; // needed for google-map-react library
  city: string;
}

const useStyles = makeStyles(() => ({
  locationPin: {
    '& > svg': {
      marginLeft: -20,
      marginTop: -30,
      width: 40,
      height: 40,
      transitionDuration: '300ms',
      '&:hover': {
        marginLeft: -30,
        marginTop: -60,
        width: 60,
        height: 60,
      },
    },
  },
}))

const countries = ['zurich', 'warsaw'];
export const MapMarker: FC<Props> = ({ city }) => {

  const classes = useStyles();
  const { dictionary } = useContext(LocalizationContext);

  return (
    <div className={classes.locationPin}>
      <Tooltip title={dictionary[city as keyof typeof dictionary]}>
        <LocationOn color={countries.includes(city) ? 'primary' : 'secondary'}/>
      </Tooltip>
    </div>
  );
}
