import React, { FC, useContext, useCallback, useEffect } from 'react';
// @ts-ignore
import Graph from 'react-graph-vis';

import { palette } from 'styles/muiTheme';
import { LayoutContext } from 'contexts/LayoutContext/LayoutContext';

const filterNodes = (nodesList: any, edgesList: any, nodesData: any, edgesData: any, useDarkTheme: boolean, nodeId?: any) => {
  const relatedNodeIds = edgesList
    .filter(({from, to}: any) => [from, to].includes(nodeId))
    .map(({from, to}: any) => {
      return from === nodeId ? to : from;
    });
  for (const node of nodesList) {
    if (nodesData?.get) {
      const thisNode = nodesData.get(node.id);
      if (thisNode) {
        if (node.id === nodeId) {
          nodesData.update({
            ...thisNode,
            color: palette.primary,
            font: {
              color: useDarkTheme ? '#ececec' : '#172232',
            },
          });
        } else if (node.level === 1) {
          nodesData.update({
            ...thisNode,
            color: palette.primary,
            font: {
              color: useDarkTheme ? '#ececec' : '#172232',
            },
          });
        } else if (relatedNodeIds.includes(node.id)) {
          nodesData.update({
            ...thisNode,
            color: palette.secondary,
            font: {
              color: useDarkTheme ? '#ececec' : '#172232',
            },
          });
        } else {
          nodesData.update({
            ...thisNode,
            color: `${useDarkTheme ? '#ececec' : '#172232'}40`,
            font: {
              color: `${useDarkTheme ? '#ececec' : '#172232'}40`,
            },
          });
        }
      }
    }
  }
};


let internalData: any = null;

export const GraphChart: FC<any> = ({ data, randomSeed = 3 }) => {

  const {useDarkTheme} = useContext(LayoutContext);

  useEffect(() => {
    if (internalData && data) {
      filterNodes(data.nodes, data.edges, internalData.nodes, internalData.edges, useDarkTheme);
    }
  }, [useDarkTheme, data]);

  const onHoverNode = useCallback(({node, ...rest}: any) => {
    if (internalData && data) {
      filterNodes(data.nodes, data.edges, internalData.nodes, internalData.edges, useDarkTheme, node);
    }
  }, [useDarkTheme, data]);

  const options = {
    layout: {
      randomSeed,
      clusterThreshold: 300,
      hierarchical: {
        enabled: false,
      },
    },
    physics: {
      enabled: true,
      repulsion: {
        centralGravity: 0,
        springLength: 200,
        springConstant: 0.05,
        nodeDistance: 100,
        damping: 0.09,
      },
      barnesHut: {
        centralGravity: 0.1,
        avoidOverlap: 0,
      },
      maxVelocity: 50,
      minVelocity: 0.1,
      solver: 'barnesHut',
      stabilization: {
        enabled: true,
        iterations: 1000,
        updateInterval: 100,
        onlyDynamicEdges: false,
        fit: true,
      },
      timestep: 0.5,
      adaptiveTimestep: true,
    },
    edges: {
      arrows: {
        to: {
          enabled: false,
        },
      },
      width: 1,
      color: {
        color: `${useDarkTheme ? '#ececec' : '#172232'}60`,
        highlight: palette.primary,
        hover: palette.primary,
      },
      smooth: {
        enabled: true,
        type: "continuous",
        roundness: 0.5,
      },
    },
    interaction: {
      tooltipDelay: 200,
      navigationButtons: true,
      zoomView: false,
      hover: true,
      dragView: false,
      keyboard: {
        enabled: false,
      },
    },
    manipulation: {
      enabled: true,
    },
    nodes: {
      widthConstraint: true,
      color: {
        border: palette.secondary,
        background: palette.secondary,
        highlight: {
          border: palette.primary,
          background: palette.primary,
        },
        hover: {
          border: palette.secondary,
          background: palette.secondary,
        },
      },
      font: {
        color: useDarkTheme ? '#ececec' : '#172232',
        face: 'itc-avant-garde-gothic-pro, sans-serif',
        size: 24,
      },
      shape: 'image',
    },
  };

  return (
    <Graph
      graph={{
        nodes: data.nodes,
        edges: data.edges,
      }}
      events={{hoverNode: onHoverNode}}
      options={options}
      getNetwork={(network: any) => {
        //@ts-ignore
        window.network = network;
        internalData = network.body.data;
      }}
    />
  )
}
