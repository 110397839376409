import React from 'react';
import { Box, styled } from '@mui/material';


const VerticalDivider = styled(Box)(() => ({
  width: '1px',
  height: '100%',

  '&.light': {
    opacity: 0.2,
    background: 'linear-gradient(180deg, rgba(255, 255, 255, 0) 0%, #FFFFFF 51.04%, rgba(255, 255, 255, 0) 100%);',
  },

  '&.dark': {
    background: 'linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, #000000 50.52%, rgba(0, 0, 0, 0) 100%);',
  },
}))

export const WhySectionComparisonItemDivider = () => {
  return (
    <Box height="33px" display="flex" justifyContent="center">
      <VerticalDivider className="light"/>
      <VerticalDivider className="dark"/>
    </Box>
  );
};
