import React, { forwardRef, useContext, Fragment } from 'react';
import { useNavigate } from 'react-router-dom';
import { isMobile } from "react-device-detect";
import { Typography } from '@mui/material';

import { LocalizationContext } from 'contexts/LocalizationContext/LocalizationContext';
import { GraphChart } from 'shared/components/GraphChart/GraphChart';
import { BaseSection } from 'shared/sections/BaseSection/BaseSection';
import { SectionTitle } from 'shared/sections/SectionTitle/SectionTitle';
import { data } from 'data/techStack.data';
import { MenuItemElement } from 'contexts/LayoutContext/LayoutContext.types';

export const TechStackSection = forwardRef<MenuItemElement>((props, ref) => {
  const navigate = useNavigate();
  const {dictionary} = useContext(LocalizationContext);

  return (
    <BaseSection ref={ref}>
      <SectionTitle onEnterViewport={() => navigate('/tech-stack')}>
        {dictionary.techStackSectionTitle}
      </SectionTitle>
      <br/>
      {isMobile ? (
        <Fragment>
          {data.nodes.map(({ label }: any) => label).join(', ')}
        </Fragment>
      ) : (
        <Fragment>
          <Typography variant="body1" fontSize={18}>
            {dictionary.techStackSectionSubtitle}
          </Typography>
          <br/><br/>
          <div style={{width: '100%', height: 1400, marginBottom: 200}}>
            <GraphChart data={data}/>
          </div>
        </Fragment>
      )}
    </BaseSection>
  );
});
