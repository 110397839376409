import React, { useEffect } from 'react';
import { Route, Routes, Navigate, useLocation } from 'react-router-dom';

import { routes } from 'config';
import { Home } from 'views/Home/Home';
import { Labs } from 'views/Labs/Labs';
import { AIAPI } from 'views/Labs/components/AIAPI';
import { PortfolioClient } from 'shared/components/PortfolioClient/PortfolioClient';
import { trackPageview } from 'tracking/tracking';


export const Root = () => {
  const location = useLocation();

  useEffect(() => {
    trackPageview();
  }, [location]);

  return (
    <Routes>
      <Route path="/" element={<Navigate to="/home" />}/>
      <Route path={routes.portfolioClient()} element={<PortfolioClient/>}/>
      <Route path={routes.labsProject('ai-api')} element={<AIAPI/>}/>
      <Route path={routes.labsProject()} element={<Labs/>}/>
      <Route path={routes.labs} element={<Labs/>}/>
      <Route path="/:sectionId" element={<Home/>}/>
    </Routes>
  );
}
