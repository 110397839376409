import { styled } from '@mui/styles';


export const PortfolioSectionClippedBackground = styled('div')(({ theme }) => ({
  position: 'absolute',
  top: 0,
  left: 0,
  width: '100%',
  height: '100%',
  backgroundColor: theme.palette.mode === 'dark' ? '#ececec' : '#ececec',
  clipPath: 'polygon(0 41%, 100% 15%, 100% 100%, 0% 100%)',
  zIndex: -1,
}));
