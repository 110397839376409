import React, { FC, ReactNode } from 'react';
import handleViewport from 'react-in-viewport';
import { Typography } from '@mui/material';
import { Variant } from '@mui/material/styles/createTypography';

import { MenuItemRef } from 'contexts/LayoutContext/LayoutContext.types';

type Props = {
  variant?: Variant;
  color?: string;
  forwardedRef: MenuItemRef | null;
  children: ReactNode;
}

const SectionTitleComponent: FC<Props> = ({
  forwardedRef,
  variant = 'h3',
  color,
  children,
}) => {

  return (
    <Typography variant={variant} color={color} ref={forwardedRef}>
      {children}
    </Typography>
  );
}

export const SectionTitle = handleViewport(SectionTitleComponent);
