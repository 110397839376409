import React, { forwardRef } from 'react';
import { useNavigate } from 'react-router';
import { Grid } from '@mui/material';

import { MenuItemElement } from 'contexts/LayoutContext/LayoutContext.types';
import { BaseSection } from 'shared/sections/BaseSection/BaseSection';
import { SectionTitle } from 'shared/sections/SectionTitle/SectionTitle';
import { successStories } from 'data/successStories.data';
import { SuccessStoryItem } from 'shared/sections/SuccessStoriesSection/SuccessStoryItem';
import { CarouselContainer } from 'shared/components/Carousel/CarouselContainer';


export const SuccessStoriesSection = forwardRef<MenuItemElement>((props, ref) => {
  const navigate = useNavigate();

  return (
    <BaseSection ref={ref}>
      <Grid item>
        <SectionTitle onEnterViewport={() => navigate('/success-stories')}>
          Our success stories
        </SectionTitle>
      </Grid>
      <Grid item py={5}>
        <CarouselContainer>
          <Grid
            container
            alignItems="flex-start"
            wrap="nowrap"
          >
            {successStories.map((story, index) => (
              <SuccessStoryItem
                key={index}
                customer={story.customer}
                jobTitle={story.jobTitle}
                company={story.company}
                linkedIn={story.linkedIn}
                avatar={story.avatar}
                video={story.video}
                videoPlaceholder={story.placeholder}
              />
            ))}
          </Grid>
        </CarouselContainer>
      </Grid>
    </BaseSection>
  )
});
