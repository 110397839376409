import React, { forwardRef, useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import { Box, Grid, Paper, Typography } from '@mui/material';
import { styled } from '@mui/styles';

import { BaseSection } from 'shared/sections/BaseSection/BaseSection';
import { SectionTitle } from 'shared/sections/SectionTitle/SectionTitle';
import { LocalizationContext } from 'contexts/LocalizationContext/LocalizationContext';
import { members } from 'data/team.data';
import { MenuItemElement } from 'contexts/LayoutContext/LayoutContext.types';

// @ts-ignore
export const TeamVideo = styled('lite-youtube')(() => ({}));


export const TeamSection = forwardRef<MenuItemElement>((props, ref) => {
  const navigate = useNavigate();
  const {dictionary} = useContext(LocalizationContext);

  return (
    <BaseSection ref={ref}>
      <Grid item style={{width: '100%', textAlign: 'center'}}>
        <SectionTitle onEnterViewport={() => navigate('/team')}>
          {dictionary.teamSectionTitle}
        </SectionTitle>
        <Box component={Paper} m="auto" mt={2} mb={2} p={2} maxWidth={1024}>
          <TeamVideo
            videotitle="CVT II Anniversary - June 2022"
            videoid="fILT_IXgL-M"
          />
        </Box>
      </Grid>
      <Grid item>
        <Grid container alignItems="center" justifyContent="center">
          {members.map(({title, subtitle, image }, i) => {
            return (
              <Grid key={i} item style={{ margin: 30 }}>
                <Grid container direction="column" justifyContent="center" alignItems="center">
                  <img
                    src={image}
                    alt={title}
                    width={200}
                    height={200}
                    style={{
                      objectFit: 'cover',
                      borderRadius: '50%',
                    }}
                  />
                  <br/>
                  <Typography variant="h5">{title}</Typography>
                  <Typography variant="body1">{subtitle}</Typography>
                </Grid>
              </Grid>
            )
          })}
        </Grid>
      </Grid>
    </BaseSection>
  );
});
